import { Controller } from "@hotwired/stimulus"
import $ from "jquery";

export default class extends Controller {
    static targets = [ 'hasOrganisation', 'shippingAddress', 'customerPhoneNumber', 'phoneCountryCode', 'phoneNumber']

    initialize() {
        $(function () {
            $("#customer-form #customers_as_create_organisation_id").select2({
                ajax: {
                    url: '/organisations.json',
                    dataType: 'json',
                    type: "GET",
                    headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')},
                    data: function (term) {
                        return {
                            term: term
                        };
                    },
                    processResults: function (data) {
                        return {
                            results: $.map(data, function (item) {
                                return {
                                    text: item.id + ', ' + item.name,
                                    id: item.id,
                                    item_name: item.name,
                                    registration_number: item.registration_number,
                                    web_url: item.web_url,
                                    vat: item.vat,
                                    description: item.description
                                }
                            })
                        };
                    }
                }
            }).on("select2:select", function (e) {
                document.getElementById('customers_as_create_organisation_attributes_name').value = e.params.data.item_name
                document.getElementById('customers_as_create_organisation_attributes_registration_number').value = e.params.data.registration_number
                document.getElementById('customers_as_create_organisation_attributes_url').value = e.params.data.web_url
                document.getElementById('customers_as_create_organisation_attributes_vat').value = e.params.data.vat
                document.getElementById('customers_as_create_organisation_attributes_description').value = e.params.data.description
            });

            let dropdownParent = $("#customer-form #payment_bill_country").parent(".form-select");
            $("#customer-form #payment_bill_country").select2({
                width: "100%",
                dropdownParent,
            });

            dropdownParent = $("#customer-form #payment_ship_country").parent(".form-select");
            $("#customer-form #payment_ship_country").select2({
                width: "100%",
                dropdownParent,
            });

            $("#customer-form #customers_as_create_date_of_birth").daterangepicker({
                singleDatePicker: true,
                timePicker: false,
                opens: "left",
                maxDate: moment(),
                autoUpdateInput: false,
                autoApply: true,
                locale: {
                    format: "DD/MM/YYYY",
                    cancelLabel: 'Clear'
                }
            })
            .on("apply.daterangepicker", function (ev, picker) {
                $("#customer-form #customers_as_create_date_of_birth").val(picker.startDate.format("DD/MM/YYYY"));
            });
        });

        $(".form-tags select").each(function () {
            const dropdownParent = $(this).parent(".form-tags");

            $(this).select2({
                placeholder: "Tags",
                width: "100%",
                tags: true,
                tokenSeparators: [","],
                dropdownParent,
            });
        });

        this.hasOrganisationUpdated()
        this.shippingAddressUpdated()

    }

    hasOrganisationUpdated(){
        if (this.hasOrganisationTarget.checked == true){
            this.showElements('onlyForOrganisation')
            this.enableInputs('organisationFields')
        } else {
            $("#customers_as_create_organisation_id").val(null).trigger('change');
            this.disableInputs('organisationFields')
            this.hideElements('onlyForOrganisation')
        }
    }

    shippingAddressUpdated(){
        if (this.shippingAddressTarget.checked == true){
            this.showElements('onlyForShippingAddress')
            this.enableInputs('shippingAddressFields')
        } else {
            this.hideElements('onlyForShippingAddress')
            this.disableInputs('shippingAddressFields')
        }
    }

    hideElements(className){
        let elements = document.getElementsByClassName(className)
        for (let i = 0; i < elements.length; i++){
            elements[i].style.display = "none";
        }
    }

    showElements(className){
        let elements = document.getElementsByClassName(className)
        for (let i = 0; i < elements.length; i++){
            elements[i].style.display = "";
        }
    }

    disableInputs(className){
        let elements = document.getElementsByClassName(className)
        for (let i = 0; i < elements.length; i++){
            elements[i].disabled = true;
        }
    }

    enableInputs(className){
        let elements = document.getElementsByClassName(className)
        for (let i = 0; i < elements.length; i++){
            elements[i].disabled = false;
        }
    }

    changedPhoneNumber() {
        this.customerPhoneNumberTarget.value = this.phoneCountryCodeTarget.value + this.phoneNumberTarget.value;
    }
}
