import consumer from "../channels/consumer"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [];

    connect() {
        this.htmlLegendPlugin = {
            id: "htmlLegend",
            afterUpdate: function (chart, args, options) {
                const ul = this.getOrCreateLegendList(chart, options.containerID);

                // Remove old legend items
                while (ul.firstChild) ul.firstChild.remove();

                // Reuse the built-in legendItems generator
                const items = chart.options.plugins.legend.labels.generateLabels(chart);

                items.forEach((item) => {
                    const {type} = chart.config;
                    const li = document.createElement("li");
                    item.hidden ? li.classList.add("inactive") : li.classList.remove("inactive");

                    li.onclick = () => {
                        if (type === "pie" || type === "doughnut") {
                            chart.toggleDataVisibility(item.index);
                        } else {
                            chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                        }
                        chart.update();
                    };

                    // Color box
                    const boxSpan = document.createElement("span");
                    boxSpan.classList.add("dot");
                    boxSpan.style.background = item.fillStyle;

                    // Text
                    const textContainer = document.createElement("span");
                    textContainer.classList.add("label");

                    const text = document.createTextNode(item.text);
                    textContainer.appendChild(text);

                    li.appendChild(boxSpan);
                    li.appendChild(textContainer);

                    if (type === "pie" || type === "doughnut") {
                        // Counters
                        if (chart.data.datasets[0].counters) {
                            const counterContainer = document.createElement("span");
                            counterContainer.classList.add("counter");

                            const counterText = document.createTextNode(chart.data.datasets[0].counters[item.index]);
                            counterContainer.appendChild(counterText);
                            li.appendChild(counterContainer);
                        }

                        // Value
                        const valueContainer = document.createElement("span");
                        valueContainer.classList.add("value");
                        var valueAmount = null
                          if (chart.canvas.id == "PaymentsProcessedToday" || chart.canvas.id == "PaymentsCapturedToday"){
                            valueAmount = this.addCommas(parseFloat(chart.data.datasets[0].data[item.index]).toFixed(2))
                          }
                          else{
                            valueAmount = chart.data.datasets[0].data[item.index]
                          }

                          const valueText = document.createTextNode(valueAmount);

                        valueContainer.appendChild(valueText);
                        li.appendChild(valueContainer);
                    }

                    ul.appendChild(li);
                });
            }.bind(this),
        };
        this.renderOrdersByStatus();
        this.renderStatusChangesToday();
        this.renderPaymentsProcessedToday();
        this.renderPaymentsCapturedToday();
        this.renderCustomers();
        this.renderProducts();
    }

    getOrCreateLegendList = (chart, id) => {
        const legendContainer = document.getElementById(id);
        let listContainer = legendContainer.querySelector("ul");

        if (!listContainer) {
            listContainer = document.createElement("ul");
            legendContainer.appendChild(listContainer);
        }

        return listContainer;
    };

    resetCanvas(canvas_id) {
        let chartStatus = Chart.getChart(canvas_id)
        if (chartStatus != undefined) {
            chartStatus.destroy();
        }
    }


    showLoader(loaderId){
        $("#" + loaderId).fadeIn()
    }

    hideLoader(loaderId){
       $("#" + loaderId).fadeOut()
    }

    addCommas(nStr) {
            nStr += '';
            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? '.' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2');
            }
            return (x1 + x2);
          }

    displayChart(loaderId){
        this.hideLoader(loaderId)
    }

    renderOrdersByStatus() {
        $.ajax({
            type: "get",
            dataType: 'json',
            start: this.displayChart("order_status_spinner"),
            url: '/dashboard/orders_by_status/',
            success: function (response) {
                this.resetCanvas("OrdersByStatus");
                
                new Chart("OrdersByStatus", {
                    type: "doughnut",
                    data: {
                        labels: ["Active", "Failed", "Paused", "Pending", "Suspended"],
                        datasets: [
                            {
                                data: response,
                                backgroundColor: ["#B2CAF0", "#EC68A3", "#eeccd9", "#3F7BDA", "#F18EBA"],
                                borderWidth: [1, 1, 1, 1, 1],
                            },
                        ],
                    },
                    options: {
                        cutout: "70%",
                        plugins: {
                            tooltip: {
                                bodySpacing: 4,
                                usePointStyle: true,
                            },
                            htmlLegend: {
                                containerID: "OrdersByStatusLegend",
                            },
                            legend: {display: false},
                        },
                    },
                    plugins: [this.htmlLegendPlugin],
                });
                this.emptyChart("OrdersByStatus")
            }.bind(this)
        })
    }

    renderStatusChangesToday() {
        $.ajax({
            type: "get",
            dataType: 'json',
            start: this.displayChart("status_change_spinner"),
            url: '/dashboard/status_changes_today/',
            success: function (response) {
                this.resetCanvas("StatusChangesToday")
                

                new Chart("StatusChangesToday", {
                    type: "doughnut",
                    data: {
                        labels: ["Active", "Failed", "Paused", "Pending", "Suspended"],
                        datasets: [
                            {
                                data: response,
                                backgroundColor: ["#B2CAF0", "#EC68A3", "#eeccd9", "#3F7BDA", "#F18EBA"],
                                borderWidth: [1, 1, 1, 1, 1],
                            },
                        ],
                    },
                    options: {
                        cutout: "70%",
                            plugins: {
                            tooltip: {
                                bodySpacing: 4,
                                    usePointStyle: true,
                            },
                            htmlLegend: {
                                containerID: "StatusChangesTodayLegend",
                            },
                            legend: {display: false},
                        },
                    },
                    plugins: [this.htmlLegendPlugin],
                });
                this.emptyChart("StatusChangesToday")
            }.bind(this)
        })
    }

    renderPaymentsProcessedToday() {
        $.ajax({
            type: "get",
            dataType: 'json',
            start: this.displayChart("payment_processed_spinner"),
            url: '/dashboard/payments_processed_today/',
            success: function (response) {
                
                this.resetCanvas("PaymentsProcessedToday")
                new Chart("PaymentsProcessedToday", {
                    type: "doughnut",
                    data: {
                        labels: ["Approved", "Declined", "Refunded"],
                        datasets: [
                            {
                                data: response.payments_processed_today,
                                counters: response.payments_processed_today_count,
                                backgroundColor: ["#6595E1", "#EC68A3", "#F18EBA"],
                                borderWidth: [1, 1, 1],
                            },
                        ],
                    },
                    options: {
                        cutout: "70%",
                            plugins: {
                            tooltip: {
                                bodySpacing: 4,
                                    usePointStyle: true,
                            },
                            htmlLegend: {
                                containerID: "PaymentsProcessedTodayLegend",
                            },
                            legend: {display: false},
                        },
                    },
                    plugins: [this.htmlLegendPlugin],
                });
                this.emptyChart("PaymentsProcessedToday")
            }.bind(this)
        })
    }

    renderPaymentsCapturedToday() {
        $.ajax({
            type: "get",
            dataType: 'json',
            url: '/dashboard/payments_captured_today/',
            start: this.displayChart("payment_capture_spinner"),
            success: function (response) {
                this.resetCanvas("PaymentsCapturedToday")
                new Chart("PaymentsCapturedToday", {
                    type: "doughnut",
                    data: {
                        labels: ["Initial", "Repeated", "Refunded"],
                        datasets: [
                            {
                                data: response.payments_capture_today,
                                counters: response.payments_capture_today_count,
                                backgroundColor: ["#6595E1", "#3F7BDA", "#EC68A3"],
                                borderWidth: [1, 1, 1],
                            },
                        ],
                    },
                    options: {
                        cutout: "70%",
                            plugins: {
                            tooltip: {
                                bodySpacing: 4,
                                    usePointStyle: true,
                            },
                            htmlLegend: {
                                containerID: "PaymentsCapturedTodayLegend",
                            },
                            legend: {display: false},
                        },
                    },
                    plugins: [this.htmlLegendPlugin],
                });
                this.emptyChart("PaymentsCapturedToday")
            }.bind(this)
        })
    }

    renderCustomers() {
        $.ajax({
            type: "get",
            dataType: 'json',
            url: '/dashboard/customers_info/',
            success: function (response) {
                $("#new_customers_count").text(response.new_customers_count);
                $("#customers_count").text(response.customers_count);
                $("#active_cust_count").text(response.active_cust_count);
                $("#inactive_cust_count").text(response.inactive_cust_count);
            }.bind(this)
        })
    }

    emptyChart(chartId){
        $("#" + chartId)
         .css({'background': "radial-gradient(circle at center, rgba(218, 230, 240, 0) 0, rgba(218, 230, 240, 0) 52%, #dae6f0 52%, #dae6f0 70%, rgba(218, 230, 240, 0) 70%, rgba(218, 230, 240, 0) 100%)"})
    }

    renderProducts() {
        $.ajax({
            type: "get",
            dataType: 'json',
            url: '/dashboard/products_info/',
            success: function (response) {
                $("#products_count").text(response.products_count);
                $("#active_prod_count").text(response.active_prod_count);
                $("#inactive_prod_count").text(response.inactive_prod_count);
                
            }.bind(this)

        })
    }
}