import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["initialAmount"]
  static values = {amountpa: Number, url: String}
  
  initialize () {
    // this.amountpaValue
  }
  
  initialAmount() {
    if (parseFloat(this.initialAmountTarget.value) <= 1) {
      return 0
    } else {
      return parseFloat(this.initialAmountTarget.value | 0)
    }
  }
  
  initialAmountUpdated(event) {
    if (parseFloat(this.initialAmountTarget.value) <= parseFloat(this.amountpaValue)) {
      return this.initialAmountTarget.value
    } else {
      this.initialAmountTarget.value = parseFloat(this.amountpaValue | 0);
    }
  }
}
