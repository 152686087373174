import consumer from "../channels/consumer"
import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        'verified3ds'
    ]

    connect() {
        this.subscription = consumer.subscriptions.create(
            {
                channel: "OrderCardRegistrationChannel",
                id: this.data.get("id"),
            },
            {
                connected: this._connected.bind(this),
                disconnected: this._disconnected.bind(this),
                received: this._received.bind(this),
            }
        );
    }

    _connected() {
    }

    _disconnected() {
    }

    _received(data) {

        if(data.status != 'draft' && data.status != 'pending' && data.status != 'rejected'){
            window.location.href = "/orders/" + this.data.get("id");
        }

    }

    updatePaymentMethod() {
        location.href = this.verified3dsTarget.dataset.url;
    }
}