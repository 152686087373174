import { Controller } from "@hotwired/stimulus"
import $ from "jquery";


export default class extends Controller {
    static targets = [ 'hasOrganisation', 'customerPhoneNumber', 'phoneCountryCode', 'phoneNumber']

    connect() {

        this.hasOrganisationUpdated()

        let dropdownParent = $("#customer-edit-form #payment_bill_country").parent(".form-select");
        $("#customer-edit-form #payment_bill_country").select2({
            placeholder: "--",
            width: "100%",
            dropdownParent,
        });
        dropdownParent = $("#customer-edit-form #payment_ship_country").parent(".form-select");
        $("#customer-edit-form #payment_ship_country").select2({
            placeholder: "--",
            width: "100%",
            dropdownParent,
        });

        const defaultDate = $("#customer-edit-form #customers_as_create_date_of_birth").val();
        $("#customer-edit-form #customers_as_create_date_of_birth").daterangepicker({
            singleDatePicker: true,
            timePicker: false,
            opens: "left",
            maxDate: moment(),
            autoUpdateInput: true,
            autoApply: true,
            locale: {
                format: "DD/MM/YYYY",
                cancelLabel: "Clear"
            }
        });
        $("#customer-edit-form #customers_as_create_date_of_birth").val(defaultDate);
    }

    hasOrganisationUpdated(){
        if (this.hasOrganisationTarget.checked == true) {
            this.showElements('organisationBlock')
            this.enableInputs('orgFields')
        } else {
            this.disableInputs('orgFields')
            this.hideElements('organisationBlock')
        }
    }

    hideElements(className){
        let elements = document.getElementsByClassName(className)
        for (let i = 0; i < elements.length; i++){
            elements[i].style.display = "none";
        }
    }

    showElements(className){
        let elements = document.getElementsByClassName(className)
        for (let i = 0; i < elements.length; i++){
            elements[i].style.display = "";
        }
    }

    disableInputs(className){
        let elements = document.getElementsByClassName(className)
        for (let i = 0; i < elements.length; i++){
            elements[i].disabled = true;
        }
    }

    enableInputs(className){
        let elements = document.getElementsByClassName(className)
        for (let i = 0; i < elements.length; i++){
            elements[i].disabled = false;
        }
    }

    changedPhoneNumber() {
        this.customerPhoneNumberTarget.value = this.phoneCountryCodeTarget.value + this.phoneNumberTarget.value;
    }
}
