import { Controller } from "@hotwired/stimulus"
import $ from "jquery";

export default class extends Controller {
  static targets = ["scheduledDateAt", "scheduledDateAtNew", "scheduledDateAtTimeNow",  ]
  
  initialize () {
    this.scheduledDateAtUpdate()
  }
  
  scheduledDateAtUpdate(event) {
    if (this.scheduledDateAtTarget.checked == true) {
      $("#initial_payment_date_div").css("display", "none").hide().fadeOut();
    } else if(this.scheduledDateAtNewTarget.checked == true) {
      $("#initial_payment_date_div").css("display", "flex").hide().fadeIn();
    } else if(this.scheduledDateAtTimeNowTarget.checked == true){
      $("#initial_payment_date_div").css("display", "none").hide().fadeOut();
    }
  }
}
