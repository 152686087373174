// Global
import "./modules/bootstrap";
import "./modules/theme";
//import "./modules/dragula";
import "./modules/feather";
import "./modules/moment";
import "./modules/sidebar";
import "./modules/toastr";
import "./modules/user-agent";

// Charts
import "./modules/chartjs";

// Forms
import "./modules/daterangepicker";
import "./modules/datetimepicker";
//import "./modules/fullcalendar";
import "./modules/mask";
import "./modules/select2";
import "./modules/validation";
import "./modules/ekko-lightbox";

// Launchers
import './launchers/datepickers'
import './launchers/global_tools'
import './launchers/cleave_js'
