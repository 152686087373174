import { Controller } from "@hotwired/stimulus"
import $ from "jquery";
import moment from "moment";


export default class extends Controller {
    static targets = [
        'customerId', 'currency', 'orderType', 'collectInitialPayment', 'initialPaymentAmount', 'durationUnit',
        'scheduledInterval', 'scheduledDurationNumber', 'collectionInterval', 'product', 'totalInstallmentsAmount',
        'recurringPaymentAmount', 'initialPaymentAmount', 'splitPayment', 'createRegistration', 'recurringStartDate',
        'recurringEndDate', 'goodUntilCancel', 'balanceAmount', 'isAllowInstalmentOnChannel', 'userId'
    ]

    static values = {
        action: String
    }

    connect() {
        $(document).off('orderFormController.updateOrderType')

        $(document).off('orderFormController.productSelectUpdated')
        $(document).on('orderFormController.productSelectUpdated', function (event, data) {
            this.productSelectUpdated(data.content, data.identifier, data.isLastProduct)
        }.bind(this))

        $(document).off('orderFormController.recalculateOrderTotals')
        $(document).on('orderFormController.recalculateOrderTotals', function () {
            this.recalculateOrderTotals()
        }.bind(this))

        $(document).off('orderFormController.removeProduct')
        $(document).on('orderFormController.removeProduct', function (event, data) {
            delete this.arrAddedProducts[data.identifier]
            $(`#${data.identifier}`).remove();

            $(document).trigger('orderFormProductController.updateProductSelect2', {excludeIds: this.arrAddedProducts})

            if ($("#products > fieldset").length > 1) {
                $("#add_product_container").css('margin-top', '0px');
            } else {
                $("#add_product_container").css('margin-top', '-50px');
            }
            $("#add_product_container").css('display', 'block');
        }.bind(this))

        this.isAllowInstalmentOnChannel = (this.isAllowInstalmentOnChannelTarget.value === 'true')
    }

    initialize() {
        this.arrAddedProducts = {};

        this.initializeCustomerSelect2();
        this.initializeCurrencySelect2();
        this.initializeScheduledDuration();
        this.initializeScheduledDurationUnit();
        this.initializeCollectionInterval();

        $('.currencySpan').val(this.currencyTarget.value);
        $(".form-control ~ .icon").click(function (e) {
            const inp = $(this).parent().find("input");
            return inp.is(":disabled") ? false : inp.focus();
        });
    }

    initializeCustomerSelect2() {
        const dropdownParent = $(`#order_customer_id`).parent(".form-select");
        $("#order_customer_id").select2({
            placeholder: "-- Select Customer --",
            width: "100%",
            dropdownParent,
            ajax: {
                url: '/customers.json',
                dataType: 'json',
                type: "GET",
                headers: {
                    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
                },
                data: function (term) {
                    return {
                        customer: term
                    };
                },
                processResults: function (data) {
                    return {
                        results: $.map(data, function (item) {
                            return {
                                text: item.customer_id + ', ' + item.first_name + ', ' + item.last_name + ', (' + item.email + ')',
                                id: item.id,
                            }
                        })
                    };
                }
            }
        });
    }

    initializeCurrencySelect2() {
        const dropdownParent = $(`#order_currency`).parent(".form-select");
        $(`#order_currency`).select2({
            placeholder: "-- Select currency --",
            width: "100%",
            dropdownParent,
        })
        .on("select2:select", function (e) {
            this.currencyUpdated();
        }.bind(this))
    }

    initializeScheduledDuration() {
        const dropdownParent = $(`#scheduleDurationSelect`).parent(".form-select");

        $(`#scheduleDurationSelect`).select2({
            placeholder: "--",
            width: "100%",
            dropdownParent,
        })
        .on("select2:select", function (e) {
            const selectedScheduledDuration = e.params.data.id

            if (selectedScheduledDuration === '1') {
                this.scheduledDurationNumberTarget.value = 1
            } else if (selectedScheduledDuration === '2') {
                this.scheduledDurationNumberTarget.value = 2
            } else if (selectedScheduledDuration === '3') {
                this.scheduledDurationNumberTarget.value = 3
            } else if (selectedScheduledDuration === 'CUSTOM') {
                document.getElementById('scheduled_duration_number_radio').style.display = "none";
                document.getElementById('scheduled_duration_number_input').style.display = "block";
            }
            this.recalculateOrderTotals()
        }.bind(this));
    }

    initializeScheduledDurationUnit() {
        const dropdownParent = $(`#order_scheduled_duration_unit`).parent(".form-select");

        $(`#order_scheduled_duration_unit`).select2({
            placeholder: "--",
            width: "100%",
            dropdownParent,
        })
        .on("select2:select", function (e) {
            this.durationUnitUpdated()
        }.bind(this));
    }

    initializeCollectionInterval() {
        const dropdownParent = $(`#collection_interval`).parent(".form-select");

        $(`#collection_interval`).select2({
            placeholder: "--",
            width: "100%",
            dropdownParent,
        })
        .on("select2:select", function (e) {
            this.collectionIntervalUpdated()
            this.scheduledIntervalUpdated()
        }.bind(this));
    }

    addProduct(onlyOneOffProduct=false, excludeIds={}, is_render=true) {
        const lastProductIndex = $("#products > fieldset:last").attr('id');
        let productNum = 0;
        if (typeof lastProductIndex !== "undefined") {
            productNum = parseInt(lastProductIndex.split('product_')[1]) + 1;
        }

        let url = `/orders/new?prod_num=${productNum}&is_render=${is_render}`
        if (Object.values(excludeIds).length > 0)
            url += `&exclude_product_ids=${Object.values(excludeIds).join()}`
        if (onlyOneOffProduct)
            url += `&product_type=single_payment`

        $.ajax({
            url: url,
            dataType: 'script',
            type: 'GET'
        });
    }

    currencyUpdated() {
        $(document).trigger('orderFormProductController.currencyUpdated', this.currencyTarget.value)
        $('.currencySpan').val(this.currencyTarget.value);
    }

    productSelectUpdated(data, identifier, isLastProduct) {
        // When only changed on top product selectbox from products, we should change order type as selected product's type
        if (data.shouldChangeOrderType) {
            this.orderTypeTarget.value = data.productType;
        }
        this.productChanged = data.productChanged;
        this.productCollectInitailPayment = data.productCollectInitailPayment;
        this.prodcutSDDurationUnit = data.prodcutSDDurationUnit;
        this.prodcutColelctionInterval = data.prodcutColelctionInterval;
        this.prodcutSDDurationNuber = data.prodcutSDDurationNuber;
        this.productInitialAmount = data.productInitialAmount;
        this.prodcutSDInterval = data.prodcutSDInterval;
        this.productLockPaymentConfig = data.productLockPaymentConfig;
        this.arrAddedProducts[identifier] = data.productId;

        if (this.orderTypeTarget.value === 'subscription') {
            $("#collection_interval option[value='WEEK']").remove();
            $("#collection_interval option[value='28DAYS']").remove();
            $("#collection_interval option[value='FORTNIGHT']").remove();
            $("#collection_interval option[value='CUSTOM']").remove();

            $("#collection_interval option[value='YEAR']").text('Yearly');
            $("#collection_interval option[value='QUARTER']").text('Quarterly');
            $("#collection_interval option[value='MONTH']").text('Monthly');
        } else {
            if (this.productChanged && data.shouldChangeOrderType) {
                $("#collection_interval option").remove();

                let option = new Option("Month", "MONTH", true, false);
                $("#collection_interval").append(option).trigger('change');
                option = new Option("Week", "WEEK", false, false);
                $("#collection_interval").append(option).trigger('change');
                option = new Option("Fortnight", "FORTNIGHT", false, false);
                $("#collection_interval").append(option).trigger('change');
                option = new Option("28 Days", "28DAYS", false, false);
                $("#collection_interval").append(option).trigger('change');
                option = new Option("Month", "MONTH", true, false);
                $("#collection_interval").append(option).trigger('change');
                option = new Option("Quarter", "QUARTER", false, false);
                $("#collection_interval").append(option).trigger('change');
                option = new Option("Year", "YEAR", false, false);
                $("#collection_interval").append(option).trigger('change');
                option = new Option("Custom", "CUSTOM", false, false);
                $("#collection_interval").append(option).trigger('change');
            }

            $("#collection_interval option[value='YEAR']").text('Year');
            $("#collection_interval option[value='QUARTER']").text('Quarter');
            $("#collection_interval option[value='MONTH']").text('Month');
        }

        if (this.productChanged && data.shouldChangeOrderType) {
            if ($(`#${identifier}`).nextAll("fieldset").length > 0) {
                $(`#${identifier}`).nextAll().each(function(index, element) {
                    delete this.arrAddedProducts[element.id]
                }.bind(this));
            }
            $(`#${identifier}`).nextAll("fieldset").remove();
        }

        $(document).trigger('orderFormProductController.updateProductSelect2', {excludeIds: this.arrAddedProducts})

        if(this.orderTypeTarget.value === 'subscription') {
            if(this.productInitialAmount > 0){
                this.collectInitialPaymentTarget.checked = true
                this.initialPaymentAmountTarget.value = this.productInitialAmount.toFixed(2)
            } else{
                this.collectInitialPaymentTarget.checked = false
                this.initialPaymentAmountTarget.value = 0.00
            }

            if (this.productChanged) {
                if(this.prodcutSDDurationUnit != ""){
                    this.durationUnitTarget.value = this.prodcutSDDurationUnit
                    this.scheduledIntervalTarget.value = this.prodcutSDInterval
                }else{
                    this.durationUnitTarget.value = 'month'
                    this.scheduledIntervalTarget.value = 1
                }

                if (this.prodcutColelctionInterval != ""){
                    this.collectionIntervalTarget.value = this.prodcutColelctionInterval

                    if (this.prodcutColelctionInterval == "CUSTOM") {
                        this.durationUnitTarget.value = this.prodcutSDDurationUnit
                        this.scheduledIntervalTarget.value = this.prodcutSDInterval
                    }
                }
            }

            this.collectionIntervalUpdated()

        }
        else if (this.orderTypeTarget.value === 'instalment') {
            const orderSplitNumber = $('#order_split_number').val();
            if (this.productChanged && data.shouldChangeOrderType)
            {
                // This is the case for order creation

                // Update form with product payment configuration
                if (this.productInitialAmount > 0)
                {
                    this.collectInitialPaymentTarget.checked = true;
                    this.initialPaymentAmountTarget.value = this.productInitialAmount.toFixed(2);
                    document.getElementById('initialPaymentAmountContainer').style.display = 'block';
                } else {
                    this.collectInitialPaymentTarget.checked = false;
                    this.initialPaymentAmountTarget.value = 0.00;
                    document.getElementById('initialPaymentAmountContainer').style.display = 'none';
                }

                if (parseInt(this.prodcutSDDurationNuber) <= 3) {
                    $("#scheduleDurationSelect").val(this.prodcutSDDurationNuber).trigger('change');
                    document.getElementById('scheduled_duration_number_input').style.display = "none";
                    document.getElementById('scheduled_duration_number_radio').style.display = "block";
                    document.getElementById('order_scheduled_duration_number').value = this.prodcutSDDurationNuber;
                } else {
                    $("#scheduleDurationSelect").val("CUSTOM").trigger('change');
                    document.getElementById('scheduled_duration_number_input').style.display = "block";
                    document.getElementById('scheduled_duration_number_radio').style.display = "none";
                    document.getElementById('order_scheduled_duration_number').value = this.prodcutSDDurationNuber;
                }

                if(this.prodcutSDDurationUnit !== "") {
                    this.durationUnitTarget.value = this.prodcutSDDurationUnit
                    this.scheduledIntervalTarget.value = this.prodcutSDInterval
                } else{
                    this.durationUnitTarget.value = 'MONTH'
                    this.scheduledIntervalTarget.value = 1
                }

                if (this.prodcutColelctionInterval !== "") {
                    this.collectionIntervalTarget.value = this.prodcutColelctionInterval

                    if (this.prodcutColelctionInterval === "CUSTOM") {
                        this.durationUnitTarget.value = this.prodcutSDDurationUnit
                        this.scheduledIntervalTarget.value = this.prodcutSDInterval
                    }
                }
            }
            else
            {
                // This is the case for order edition
                if (this.collectInitialPaymentTarget.checked) {
                    document.getElementById('initialPaymentAmountContainer').style.display = 'block';
                } else {
                    document.getElementById('initialPaymentAmountContainer').style.display = 'none';
                }

                this.scheduledDurationNumberTarget.value = orderSplitNumber;

                if (parseInt(orderSplitNumber) <= 3) {
                    $("#scheduleDurationSelect").val(this.scheduledDurationNumberTarget.value).trigger('change');
                    document.getElementById('scheduled_duration_number_input').style.display = "none";
                    document.getElementById('scheduled_duration_number_radio').style.display = "block";
                    document.getElementById('order_scheduled_duration_number').value = this.scheduledDurationNumberTarget.value
                } else if (parseInt(orderSplitNumber) > 3) {
                    document.getElementById('order_scheduled_duration_number').value = this.scheduledDurationNumberTarget.value
                    $("#scheduleDurationSelect").val("CUSTOM").trigger('change');
                    document.getElementById('scheduled_duration_number_input').style.display = "block";
                    document.getElementById('scheduled_duration_number_radio').style.display = "none";
                }
            }

            this.collectionIntervalUpdated()

        }
        else if (this.orderTypeTarget.value == 'metered'){
            if (this.productChanged) {
                this.initialPaymentAmountTarget.value = this.productInitialAmount.toFixed(2)
                this.collectInitialPaymentTarget.checked = true
            }
        }

        this.recalculateOrderTotals()
        if (this.orderTypeTarget.value == 'instalment' || this.orderTypeTarget.value == 'subscription'){
            this.collectionIntervalUpdated()
        }

        if (this.orderTypeTarget.value == 'single_payment') {
            if (this.productChanged)
                this.addProduct(true, this.arrAddedProducts, false)
            else if (isLastProduct)
                this.addProduct(true, this.arrAddedProducts, false)
        } else {
            $("#add_product_container").css('display', 'none');
        }

        // When editing an Instalment order with single_payment products
        if (this.orderTypeTarget.value === "instalment" && this.actionValue === "edit" && data.productType === "single_payment") {
            this.addProduct(true, this.arrAddedProducts, false)
        }

        this.initializeScheduledDuration();
        this.initializeScheduledDurationUnit();
        this.initializeCollectionInterval();
    }

    collectInitialPaymentUpdated() {
        if (this.collectInitialPaymentTarget.checked) {
            if (this.orderTypeTarget.value === 'single_payment') {
                this.initialPaymentAmountTarget.disabled = false
                this.initialPaymentAmountTarget.readOnly = true
            } else if (this.orderTypeTarget.value === 'instalment') {
                this.initialPaymentAmountTarget.readOnly = false
                this.initialPaymentAmountTarget.disabled = false
                $("#initialPaymentAmountContainer").css("display", "block").fadeIn();
                $("#balanceAmountContainer").css("display", "block").fadeIn();
            }
        } else {
            this.initialPaymentAmountTarget.value = 0.00
            this.initialAmount = 0
            $("#initialPaymentAmountContainer").css("display", "none").fadeOut();
            $("#balanceAmountContainer").css("display", "none").fadeOut();

            if (this.orderTypeTarget.value === 'single_payment') {
                this.initialPaymentAmountTarget.readOnly = false
            } else if (this.orderTypeTarget.value === 'subscription') {
                this.initialPaymentAmountTarget.disabled = true
            } else if (this.orderTypeTarget.value === 'instalment') {
                this.initialPaymentAmountTarget.disabled = false
            }
            this.recalculateOrderTotals()
        }
    }

    setRecurringDatePicker(number, unit) {
        $("#order_start_date").daterangepicker({
            autoUpdateInput: true,
            buttonClasses: "btn btn-sm",
            applyButtonClasses: "btn-primary",
            cancelClass: "btn-info",
            parentEl: $("#order_start_date").parent(),
            singleDatePicker: true,
            timePicker: true,
            opens: "left",
            timePicker24Hour: true,
            minDate: moment().startOf("day").add(31, "hour"),
            startDate: moment().add(number, unit),
            locale: {
                format: "DD/MM/YYYY HH:mm",
                cancelLabel: "Clear"
            }
        })
        .on("apply.daterangepicker", function (ev, picker) {
            if ($("#order_start_date").data("time") == "show") {
                $("#order_start_date").val(picker.startDate.format("DD/MM/YYYY HH:mm"));
            } else {
                $("#order_start_date").val(picker.startDate.format("DD/MM/YYYY"));
            }
        })
        .on("cancel.daterangepicker", function () {
            $("#order_start_date").val("");
        });
    }

    collectionIntervalUpdated() {
        document.getElementById('collection_interval_select').style.display = 'block';

        if ($("#collection_interval").val() === 'MONTH') {
            this.scheduledIntervalTarget.value = 1;
            this.durationUnitTarget.value = 'MONTH';

            $("#collection_interval_custom").css("display", "none").fadeOut();
            this.setRecurringDatePicker(1, 'month');
        }
        else if ($("#collection_interval").val() === 'YEAR') {
            this.scheduledIntervalTarget.value = 1;
            this.durationUnitTarget.value = 'YEAR';

            $("#collection_interval_custom").css("display", "none").fadeOut();
            this.setRecurringDatePicker(1, 'year');
        }
        else if ($("#collection_interval").val() === 'WEEK') {
            this.scheduledIntervalTarget.value = 1;
            this.durationUnitTarget.value = 'WEEK';

            $("#collection_interval_custom").css("display", "none").fadeOut();
            this.setRecurringDatePicker(1, 'week');
        }
        else if ($("#collection_interval").val() === '28DAYS') {
            this.scheduledIntervalTarget.value = 28;
            this.durationUnitTarget.value = 'DAY';

            $("#collection_interval_custom").css("display", "none").fadeOut();
            this.setRecurringDatePicker(28, 'day');
        }
        else if ($("#collection_interval").val() === 'FORTNIGHT') {
            this.scheduledIntervalTarget.value = 14;
            this.durationUnitTarget.value = 'DAY';

            $("#collection_interval_custom").css("display", "none").fadeOut();
            this.setRecurringDatePicker(14, 'day');
        }
        else if ($("#collection_interval").val() === 'QUARTER') {
            this.scheduledIntervalTarget.value = 3;
            this.durationUnitTarget.value = 'MONTH';

            $("#collection_interval_custom").css("display", "none").fadeOut();
            this.setRecurringDatePicker(3, 'month');
        }
        else if ($("#collection_interval").val() === 'CUSTOM') {
            $("#collection_interval_custom").css("display", "block").fadeIn();
            $("#collection_interval_select").css("display", "none").fadeOut();
            this.setRecurringDatePicker(this.scheduledIntervalTarget.value, this.durationUnitTarget.value);
        }

        this.initializeScheduledDuration();
        this.initializeScheduledDurationUnit();
        this.initializeCollectionInterval();
    }

    recalculateOrderTotals() {
        this.totalProductAndFee = 0;
        this.discountAmount = 0;
        this.productFee = 0;
        this.productTotal = 0;
        this.initialDiscountAmount = 0;
        this.initialFeeAmount = 0;
        this.totalTaxAmount = 0;
        const thisPointer = this;

        $("div#products > fieldset").each(function () {
            const subTotal = parseFloat($(this).find("#product_taxes_sub_total").val());
            thisPointer.totalProductAndFee += subTotal || 0.00;

            const selectedDiscountValue = $(this).find(".discount-select option:selected").val();
            const selectedDiscountAmount = parseFloat($(this).find(".discount-select option:selected").attr('data-amount')) || 0.00;
            const discountPercentage = parseFloat($(this).find(".discount-select option:selected").attr('data-percentage')) || 0.00;
            const discountForInitialOnly = $(this).find("#discount-for-initial-only").val();
            const feeForInitialOnly = $(this).find("#fee-for-initial-only").val();
            const productQty = parseFloat($(this).find("#order_products_qty").val());
            const productAmount = parseFloat($(this).find("#order_products_amount").val());
            const discountCustomAmount = parseFloat($(this).find("#product_discounts_amount").val());
            const selectedFeeValue = $(this).find(".fee-select option:selected").val();
            const feeAmount = parseFloat($(this).find(".fee-select option:selected").attr('data-amount')) || 0.00;
            const customFeeAmount = parseFloat($(this).find("#order_product_fee_amount").val()) || 0.00;
            const taxAmount = parseFloat($(this).find("#product_taxes_amount").val());

            const productTotal = (productQty * productAmount) || 0.00;
            thisPointer.productTotal += productTotal;

            // Discount
            if (selectedDiscountValue !== '' && parseFloat(discountPercentage) > 0) {
                if(discountForInitialOnly === '1') {
                    thisPointer.discountAmount += parseFloat('0')
                    thisPointer.initialDiscountAmount += parseFloat(productTotal * (discountPercentage / 100))
                } else {
                    thisPointer.discountAmount += parseFloat(productTotal * (discountPercentage / 100))
                    thisPointer.initialDiscountAmount += parseFloat(productTotal * (discountPercentage / 100))
                }
            } else if (selectedDiscountValue !== '' && selectedDiscountAmount > 0) {
                if(discountForInitialOnly === '1'){
                    thisPointer.discountAmount += parseFloat(0)
                    thisPointer.initialDiscountAmount += parseFloat(selectedDiscountAmount)
                } else {
                    thisPointer.discountAmount += parseFloat(selectedDiscountAmount)
                    thisPointer.initialDiscountAmount += parseFloat(selectedDiscountAmount)
                }
            } else {
                if(discountForInitialOnly === '1') {
                    thisPointer.discountAmount += 0
                    thisPointer.initialDiscountAmount += (discountCustomAmount || 0)
                } else {
                    thisPointer.discountAmount += (discountCustomAmount || 0)
                    thisPointer.initialDiscountAmount += (discountCustomAmount || 0)
                }
            }

            // Fee
            if (feeForInitialOnly === '1' && feeAmount > 0) {
                thisPointer.productFee += 0.0;
                thisPointer.initialFeeAmount += feeAmount;
            } else if (feeForInitialOnly !== '1' && feeAmount > 0) {
                thisPointer.productFee += feeAmount;
                thisPointer.initialFeeAmount += feeAmount;
            } else {
                if (feeForInitialOnly === '1') {
                    thisPointer.productFee += 0;
                    thisPointer.initialFeeAmount += (customFeeAmount || 0.0);
                } else {
                    thisPointer.productFee += (customFeeAmount || 0.0);
                    thisPointer.initialFeeAmount += (customFeeAmount || 0.0);
                }
            }

            // Tax
            thisPointer.totalTaxAmount += taxAmount || 0.00
        })

        if (this.orderTypeTarget.value == '' || this.orderTypeTarget.value == 'single_payment')
        {
            this.recurringAmount = parseFloat('0');
            this.subTotal =0;
            this.totalToBePaid =0;
            this.productTotal = 0;
            this.initialAmount = 0;
            this.totalTaxAmount = 0;

            $("div#products > fieldset").each(function () {
                const subTotal = parseFloat($(this).find("#product_taxes_sub_total").val());
                const productQty = parseFloat($(this).find("#order_products_qty").val());
                const productAmount = parseFloat($(this).find("#order_products_amount").val());
                const taxAmount = parseFloat($(this).find("#product_taxes_amount").val());

                thisPointer.subTotal += subTotal || 0.00;
                thisPointer.totalToBePaid += subTotal || 0.00;
                thisPointer.productTotal += (productQty * productAmount) || 0.00

                thisPointer.initialAmount += subTotal || 0.00
                thisPointer.totalTaxAmount += taxAmount || 0.00
            })

            this.totalInstallmentsAmountTarget.value = parseFloat('0').toFixed(2) || 0.00
            this.recurringPaymentAmountTarget.value = parseFloat('0').toFixed(2) || 0.00
            this.initialPaymentAmountTarget.value = this.totalToBePaid.toFixed(2) || 0.00

        }
        else if (this.orderTypeTarget.value == 'instalment')
        {
            this.subTotal = 0;
            this.totalToBePaid = 0;
            this.productTotal = 0;
            this.totalTaxAmount = 0;

            $("div#products > fieldset").each(function () {
                const subTotal = parseFloat($(this).find("#product_taxes_sub_total").val());
                const productQty = parseFloat($(this).find("#order_products_qty").val());
                const productAmount = parseFloat($(this).find("#order_products_amount").val());
                const taxAmount = parseFloat($(this).find("#product_taxes_amount").val());

                thisPointer.subTotal += subTotal || 0.00;
                thisPointer.totalToBePaid += subTotal || 0.00;
                thisPointer.productTotal += (productQty * productAmount) || 0.00
                thisPointer.totalTaxAmount += taxAmount || 0.00
            })

            this.initialAmount = (parseFloat(this.initialPaymentAmountTarget.value) || 0.00)
            this.recurringAmount = parseFloat((this.subTotal - this.initialAmount) / (parseInt(this.scheduledDurationNumberTarget.value) || 1))


            let t = this.initialPaymentAmountTarget.value;
            this.initialPaymentAmountTarget.value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;

            this.totalInstallmentsAmountTarget.value = this.subTotal.toFixed(2);
            this.recurringPaymentAmountTarget.value = this.recurringAmount.toFixed(2)

            this.instalmentBalance = this.totalToBePaid - this.initialAmount
            this.balanceAmountTarget.value = parseFloat(this.instalmentBalance).toFixed(2)
        }
        else if (this.orderTypeTarget.value == 'subscription')
        {
            this.initialAmount = 0;
            this.recurringAmount = 0;

            this.initialAmount = this.productTotal - this.initialDiscountAmount + this.initialFeeAmount;
            this.recurringAmount = this.productTotal - this.discountAmount + this.productFee;

            this.subTotal = parseFloat(this.initialAmount)
            this.totalToBePaid = parseFloat(this.initialAmount)
            this.initialPaymentAmountTarget.value = this.initialAmount.toFixed(2)
            this.totalInstallmentsAmountTarget.value = parseFloat('0').toFixed(2)
            this.recurringPaymentAmountTarget.value = this.recurringAmount.toFixed(2)
        }
        else if (this.orderTypeTarget.value == 'metered')
        {
            this.totalToBePaid = 0;
            this.totalTaxAmount = 0;
            this.productTotal = 0;

            $("div#products > fieldset").each(function () {
                const subTotal = parseFloat($(this).find("#product_taxes_sub_total").val());
                const taxAmount = parseFloat($(this).find("#product_taxes_amount").val());
                const productQty = parseFloat($(this).find("#order_products_qty").val());
                const productAmount = parseFloat($(this).find("#order_products_amount").val());

                thisPointer.totalToBePaid += subTotal;
                thisPointer.totalTaxAmount += taxAmount || 0.00;
                thisPointer.productTotal += (productQty * productAmount) || 0.00
            });

            this.initialAmount = parseFloat(this.totalToBePaid);
            this.recurringAmount = parseFloat('0');
            this.subTotal = parseFloat(this.totalToBePaid);

            this.initialPaymentAmountTarget.value = this.totalToBePaid.toFixed(2) || 0.00;
            this.totalInstallmentsAmountTarget.value = parseFloat('0').toFixed(2) || 0.00;
            this.recurringPaymentAmountTarget.value = parseFloat('0').toFixed(2) || 0.00;
        }

        this.updateOrderTotals()
        this.renderFormsForOrder()
    }

    updateOrderTotals() {
        document.getElementById('totalsSummarySubTotal').innerHTML = this.getFormattedCurrency(parseFloat(this.totalProductAndFee || 0.00).toFixed(2));
        // document.getElementById('totalsSummaryProductsTotal').innerHTML = parseFloat(this.prodcutsTotalMinusVat).toFixed(2);
        document.getElementById('totalsInitialSummaryFee').innerHTML = this.getFormattedCurrency(parseFloat(this.initialFeeAmount || 0.00).toFixed(2));
        document.getElementById('totalsInitialSummaryDiscount').innerHTML = this.getFormattedCurrency(parseFloat(-Math.abs(this.initialDiscountAmount || 0.00)).toFixed(2));
        document.getElementById('totalsSummaryFee').innerHTML = this.getFormattedCurrency(parseFloat(this.productFee || 0.00).toFixed(2));
        document.getElementById('totalsSummaryDiscount').innerHTML = this.getFormattedCurrency(parseFloat(-Math.abs(this.discountAmount || 0.00)).toFixed(2));
        // document.getElementById('totalsSummaryTax').innerHTML = parseFloat(this.taxAmount).toFixed(2);
        document.getElementById('totalsSummaryInitialAmount').innerHTML = this.getFormattedCurrency(parseFloat(this.initialAmount || 0.00).toFixed(2));
        document.getElementById('totalsSummaryRecurringAmount').innerHTML = this.getFormattedCurrency(parseFloat(this.recurringAmount || 0.00).toFixed(2));
        document.getElementById('totalsSummaryTotalToPay').innerHTML = this.getFormattedCurrency(parseFloat(this.initialAmount || 0.00).toFixed(2));
        document.getElementById('totalsInitialSummaryDiscount').innerHTML = this.getFormattedCurrency(parseFloat(-Math.abs(this.initialDiscountAmount || 0.00)).toFixed(2));
        // document.getElementById('totalsSummaryTaxableAmount').innerHTML = (this.totalTaxableAmount || 0.00).toFixed(2);
        document.getElementById('totalsSummaryTaxAmount').innerHTML = this.getFormattedCurrency((this.totalTaxAmount || 0.00).toFixed(2));
        document.getElementById('totalsSummaryProductPrice').innerHTML = this.getFormattedCurrency((this.productTotal || 0.00).toFixed(2));

        if (this.orderTypeTarget.value === 'subscription') {
            document.getElementById('order_total_discount').value = this.getFormattedCurrency((this.initialDiscountAmount || 0.00).toFixed(2));
            document.getElementById('order_total_fee').value = this.getFormattedCurrency((this.initialFeeAmount || 0.00).toFixed(2));
        } else {
            document.getElementById('order_total_discount').value = this.getFormattedCurrency((this.discountAmount || 0.00).toFixed(2));
            document.getElementById('order_total_fee').value = this.getFormattedCurrency((this.productFee || 0.00).toFixed(2));
        }
        document.getElementById('order_total_tax').value = this.getFormattedCurrency((this.totalTaxAmount || 0.00).toFixed(2));

        document.getElementById('totalsSummaryBalanceTr').style.display = 'none';
        document.getElementById('totalsSummarySplitByTr').style.display = 'none';

        if (this.orderTypeTarget.value === 'single_payment') {
            document.getElementById('totalsSummaryRecurringAmountTr').style.display = 'none'
            document.getElementById('totalsInitialSummaryDiscountTr').style.display = 'none'
            document.getElementById('totalsInitialSummaryFeeTr').style.display = 'none'
            document.getElementById('totalsSummaryInitialAmountTr').style.display = 'none'
        } else if (this.orderTypeTarget.value === 'subscription') {
            document.getElementById('totalsInitialSummaryDiscountTr').style.display = ''
            document.getElementById('totalsInitialSummaryFeeTr').style.display = ''

            document.getElementById('totalsSummaryInitialAmountTr').style.display = ''
            document.getElementById('totalsSummaryRecurringAmountTr').style.display = ''
            document.getElementById('totalsSummaryTotalToPayTr').style.display = 'none'
        } else if (this.orderTypeTarget.value === 'instalment') {
            document.getElementById('totalsSummaryBalance').innerHTML = this.getFormattedCurrency(this.instalmentBalance.toFixed(2))
            document.getElementById('totalsSummarySplitBy').innerHTML = this.scheduledDurationNumberTarget.value

            document.getElementById('totalsSummarySplitByTr').style.display = ''
            document.getElementById('totalsSummaryRecurringAmountTr').style.display = ''
            document.getElementById('totalsInitialSummaryDiscountTr').style.display = 'none'
            document.getElementById('totalsInitialSummaryFeeTr').style.display = 'none'
            document.getElementById('totalsSummaryInitialAmountTr').style.display = ''
            document.getElementById('totalsSummaryBalanceTr').style.display = ''
        } else if (this.orderTypeTarget.value === 'metered') {
            document.getElementById('totalsSummaryRecurringAmountTr').style.display = 'none'
            document.getElementById('totalsInitialSummaryDiscountTr').style.display = 'none'
            document.getElementById('totalsInitialSummaryFeeTr').style.display = 'none'
            document.getElementById('totalsSummaryInitialAmountTr').style.display = 'none'
            document.getElementById('totalsSummaryTotalToPayTr').style.display = ''
        }

        if (this.orderTypeTarget.value == "subscription") {
            $('#totalsSummaryDiscountTr th').text('Recurring Discount');
            $('#totalsSummaryFeeTr th').text('Recurring Fee');
        } else {
            $('#totalsSummaryDiscountTr th').text('Discount');
            $('#totalsSummaryFeeTr th').text('Fee');
        }

        document.getElementById('totalsSummaryDiscountTr').style.display = ''
        document.getElementById('totalsSummaryFeeTr').style.display = ''
        document.getElementById('totalsSummaryTaxAmountTr').style.display = ''
    }

    renderFormsForOrder() {
        if (this.orderTypeTarget.value == 'single_payment') {
            this.renderOneOffPaymentForms()
        } else if (this.orderTypeTarget.value == 'subscription') {
            this.renderRecuringPaymentForms()
        } else if (this.orderTypeTarget.value == 'instalment') {
            this.renderInstalmentPaymentForms()
        } else if (this.orderTypeTarget.value == 'metered') {
            this.renderMeteredForms()
            this.collectInitialPaymentTarget.checked = true
        }

        this.checkIfLookPricing()
    }

    renderOneOffPaymentForms() {

        this.showInputs('saveCardContainer')
        this.hideInputs('collectDepositContainer')

        this.hideInputs('initialPaymentAmountContainer')
        this.hideInputs('recurringAmountContainer')
        this.hideInputs('recurringIntervalContainer')
        this.hideInputs('startDateContainer')
        this.hideInputs('balanceAmountContainer')
        this.hideInputs('splitNumberContainer')

        // On one_off product, checks that instalment option is allowed on processing_details,
        // then, if it is not allowed, hides `split payment` toggle element
        if (!this.isAllowInstalmentOnChannel) {
            this.hideInputs('splitPaymentsContainer')
        } else {
            this.showInputs('splitPaymentsContainer')
        }

        document.getElementById('recurring_end_date_div').style.display = 'none'

        // $("#splitPaymentsContainer label").css('display', 'flex');

        this.collectInitialPaymentTarget.disabled = false
        this.collectInitialPaymentTarget.checked = true
        this.splitPaymentTarget.checked = false
        this.splitPaymentTarget.disabled = false
        this.initialPaymentAmountTarget.disabled = false
        this.initialPaymentAmountTarget.readOnly = true
        this.scheduledDurationNumberTarget.disabled = true
        this.recurringPaymentAmountTarget.disabled = true
        this.scheduledIntervalTarget.disabled = true
        this.recurringStartDateTarget.disabled = true

        this.createRegistrationTarget.disabled = false
        if (this.productChanged) {
            this.createRegistrationTarget.checked = false
        }

        this.recurringEndDateTarget.disabled = true
        this.durationUnitTarget.disabled = true
        this.collectionIntervalTarget.disabled = true
        // this.goodUntilCancelTarget.disable = true
        // this.goodUntilCancelTarget.checked = false
    }

    renderRecuringPaymentForms() {
        this.hideInputs('collectDepositContainer')
        this.hideInputs('splitPaymentsContainer')
        this.hideInputs('saveCardContainer')

        this.showInputs('initialPaymentAmountContainer')
        this.showInputs('recurringAmountContainer')
        this.showInputs('recurringIntervalContainer')
        this.showInputs('startDateContainer')
        this.hideInputs('balanceAmountContainer')
        this.hideInputs('splitNumberContainer')

        document.getElementById('recurring_end_date_div').style.display = 'none'

        if (parseFloat(this.initialPaymentAmountTarget.value) > 0) {
            this.collectInitialPaymentTarget.checked = true
            document.getElementById('initialPaymentAmountContainer').style.display = 'block'
        } else {
            this.collectInitialPaymentTarget.checked = false
        }

        this.collectInitialPaymentTarget.disabled = false
        this.initialPaymentAmountTarget.disabled = false
        this.initialPaymentAmountTarget.readOnly = true
        this.scheduledDurationNumberTarget.disabled = false
        this.recurringPaymentAmountTarget.disabled = false
        this.recurringPaymentAmountTarget.readOnly = true
        this.scheduledIntervalTarget.disabled = false
        this.recurringStartDateTarget.disabled = false
        this.createRegistrationTarget.disabled = false
        this.createRegistrationTarget.checked = true
        this.recurringEndDateTarget.disabled = false
        this.durationUnitTarget.disabled = false
        this.collectionIntervalTarget.disabled = false
    }

    renderInstalmentPaymentForms() {

        this.showInputs('collectDepositContainer')
        this.showInputs('splitPaymentsContainer')
        this.hideInputs('saveCardContainer')

        if (this.collectInitialPaymentTarget.checked) {
            this.showInputs('initialPaymentAmountContainer')
            this.showInputs('balanceAmountContainer')
        } else {
            this.hideInputs('initialPaymentAmountContainer')
            this.hideInputs('balanceAmountContainer')
        }

        this.showInputs('recurringAmountContainer')
        this.showInputs('recurringIntervalContainer')
        this.showInputs('startDateContainer')
        this.showInputs('splitNumberContainer')

        this.recurringPaymentAmountTarget.disabled = false
        this.recurringPaymentAmountTarget.readOnly = true

        this.collectInitialPaymentTarget.disabled = false
        this.splitPaymentTarget.checked = true
        this.splitPaymentTarget.disabled = false

        this.scheduledDurationNumberTarget.disabled = false
        this.scheduledIntervalTarget.disabled = false
        this.recurringStartDateTarget.disabled = false

        this.recurringEndDateTarget.disabled = true
        this.durationUnitTarget.disabled = false
        this.collectionIntervalTarget.disabled = false

        this.initialPaymentAmountTarget.disabled = false
        this.initialPaymentAmountTarget.readOnly = false
        this.createRegistrationTarget.disabled = false
        this.createRegistrationTarget.checked = true

        if (this.productChanged) {
            // This is the case for order creation
            let selectedScheduledDuration = $("#scheduleDurationSelect option:selected").val()
            if (parseInt(selectedScheduledDuration) <= 3 && (parseInt(this.prodcutSDDurationNuber) <= 3)) {
                $("#scheduleDurationSelect").val(this.scheduledDurationNumberTarget.value).trigger('change');
                document.getElementById('scheduled_duration_number_input').style.display = "none";
                document.getElementById('scheduled_duration_number_radio').style.display = "block";
                document.getElementById('order_scheduled_duration_number').value = this.scheduledDurationNumberTarget.value
            } else if (selectedScheduledDuration === 'CUSTOM' || (parseInt(this.prodcutSDDurationNuber) > 3) ) {
                document.getElementById('order_scheduled_duration_number').value = this.scheduledDurationNumberTarget.value
                document.getElementById('scheduled_duration_number_input').style.display = "block";
                document.getElementById('scheduled_duration_number_radio').style.display = "none";
            } else {
                $("#scheduleDurationSelect").val(1).trigger('change');
                document.getElementById('scheduled_duration_number_input').style.display = "none";
                document.getElementById('scheduled_duration_number_radio').style.display = "block";
                this.scheduledDurationNumberTarget.value = 1
            }
        }

        document.getElementById('recurring_end_date_div').style.display = 'none';
    }

    renderMeteredForms() {
        this.hideInputs('collectDepositContainer')
        this.hideInputs('splitPaymentsContainer')
        this.hideInputs('saveCardContainer')

        this.showInputs('initialPaymentAmountContainer')
        this.hideInputs('recurringAmountContainer')
        this.hideInputs('recurringIntervalContainer')
        this.hideInputs('startDateContainer')
        this.hideInputs('balanceAmountContainer')
        this.hideInputs('splitNumberContainer')

        document.getElementById('recurring_end_date_div').style.display = 'none'

        this.collectInitialPaymentTarget.disabled = false
        this.collectInitialPaymentTarget.checked = true
        this.splitPaymentTarget.checked = false
        this.initialPaymentAmountTarget.disabled = false

        if(this.productLockPaymentConfig){
            this.initialPaymentAmountTarget.readOnly = true
        } else {
            this.initialPaymentAmountTarget.readOnly = false
        }

        this.scheduledDurationNumberTarget.disabled = true
        this.recurringPaymentAmountTarget.disabled = true
        this.scheduledIntervalTarget.disabled = true
        this.recurringStartDateTarget.disabled = true
        this.recurringEndDateTarget.disabled = true
        this.durationUnitTarget.disabled = false
        this.collectionIntervalTarget.disabled = true
        // this.goodUntilCancelTarget.disable = true
        // this.goodUntilCancelTarget.checked = false

        this.createRegistrationTarget.disabled = false
        this.createRegistrationTarget.checked = true
    }

    checkIfLookPricing() {
        if (this.productLockPaymentConfig) {
            if (this.orderTypeTarget.value === 'instalment') {
                document.getElementById('collectDepositContainer').style.display = 'none';
                this.hideInputs('splitPaymentsContainer');
                this.lockPaymentConfigElements();
            } else if (this.orderTypeTarget.value === 'subscription') {
                this.lockPaymentConfigElements();
            } else {
                this.releasePaymentConfigElements();
            }
        } else {
            this.releasePaymentConfigElements();
        }
    }

    lockPaymentConfigElements() {
        this.balanceAmountTarget.readOnly = true;
        this.scheduledDurationNumberTarget.readOnly = true;
        this.recurringPaymentAmountTarget.readOnly = true;
        this.initialPaymentAmountTarget.readOnly = true;
        this.scheduledIntervalTarget.readOnly = true;

        $("#collection_interval option:not(:selected)").attr("disabled", "disabled");
        $("#order_scheduled_duration_unit option:not(:selected)").attr("disabled", "disabled");
        $("select[name=scheduleDurationSelect] option:not(:selected)").attr("disabled", "disabled");
    }

    releasePaymentConfigElements() {
        this.balanceAmountTarget.readOnly = true;
        this.scheduledDurationNumberTarget.readOnly = false;
        this.scheduledIntervalTarget.readOnly = false;

        $("#lbCountries").click(function () {
            $("#lbCountires option").not(':first-child').each(function (index) {
                $(this).prop('disabled', true);
            });
        });

        if (this.orderTypeTarget.value === 'subscription') {
            this.recurringPaymentAmountTarget.readOnly = true;
            this.initialPaymentAmountTarget.readOnly = true;
        } else if (this.orderTypeTarget.value === 'instalment') {
            this.recurringPaymentAmountTarget.readOnly = true;
            this.showInputs('splitPaymentsContainer')
        } else if (this.orderTypeTarget.value === 'metered') {
            this.initialPaymentAmountTarget.readOnly = true;
        } else {
            this.recurringPaymentAmountTarget.readOnly = false;
            this.initialPaymentAmountTarget.readOnly = false;
        }

        if (this.orderTypeTarget.value === 'single_payment' && !this.isAllowInstalmentOnChannel) {
            this.hideInputs('splitPaymentsContainer')
        }

        $("#collection_interval option").removeAttr("disabled");
        $("#order_scheduled_duration_unit option").removeAttr("disabled");
        $("select[name=scheduleDurationSelect] option").removeAttr("disabled");
    }

    disableInputs(className) {
        let elements = document.getElementsByClassName(className)
        for (let i = 0; i < elements.length; i++) {
            elements[i].disabled = true;
        }
    }

    enableInputs(className) {
        let elements = document.getElementsByClassName(className)
        for (let i = 0; i < elements.length; i++) {
            elements[i].disabled = false;
        }
    }

    hideInputs(className) {
        let elements = document.getElementsByClassName(className)
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.display = 'none'
        }
    }

    showInputs(className) {
        let elements = document.getElementsByClassName(className)
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.display = 'block';
        }
    }

    splitPaymentUpdated() {
        if (this.splitPaymentTarget.checked) {
            this.orderTypeTarget.value = 'instalment'
            this.initialPaymentAmountTarget.value = 0.00

            this.collectInitialPaymentTarget.checked = true
            $("#initialPaymentAmountContainer").css("display", "block").fadeIn();
            $("#balanceAmountContainer").css("display", "block").fadeIn();

            $("#add_product_container").css("display", "none");
        } else {
            this.orderTypeTarget.value = 'single_payment'
        }

        document.getElementById('scheduled_duration_number_input').style.display = "none";
        document.getElementById('scheduled_duration_number_radio').style.display = "block";

        this.recalculateOrderTotals()
        this.collectionIntervalUpdated()
    }

    durationUnitUpdated(event) {
        let selected_value = $("#order_scheduled_duration_unit option:selected").val();

        $("#order_recurring_end_date").daterangepicker({
            singleDatePicker: true,
            timePicker: true,
            opens: "left",
            timePicker24Hour: true,
            minDate: moment().startOf("day").add(1, selected_value),
            startDate: moment().add(2, selected_value),
            autoUpdateInput: true,
            locale: {
                format: "DD/MM/YYYY HH:mm"
            }
        });
    }

    scheduledIntervalUpdated() {
        this.collectionIntervalUpdated()
    }

    getFormattedCurrency(price) {
        let locale = 'en-GB';

        const formatString = Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return formatString.format(price);
    }

    addProductHandler() {
        this.addProduct(true, this.arrAddedProducts, true)
    }
}