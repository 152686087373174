import { Controller } from "@hotwired/stimulus"
import $ from "jquery";
import moment from "moment";

export default class extends Controller {
  static targets = [ "pauseAuto", "pauseManually" ]

  initialize () {
    $(".start_date_times").daterangepicker({
      autoUpdateInput: true,
      buttonClasses: "btn btn-sm",
      applyButtonClasses: "btn-primary",
      cancelClass: "btn-info",
      parentEl: $(".start_date_times").parent(),
      singleDatePicker: true,
      timePicker: true,
      opens: "left",
      timePicker24Hour: true,
      minDate: moment(),
      startDate: moment(),
      locale: {
        format: "DD/MM/YYYY HH:mm"
      }
    });

    this.pauseUntilAtUpdate()
  }

  pauseUntilAtUpdate(event) {
    if (this.pauseManuallyTarget.checked == true) {
      $("#initial_payment_date_div").css("display", "none").hide().fadeOut();
      $("#default_collection_date").css("display", "none").hide().fadeOut();
      $("#update_collection_date").css("display", "none").hide().fadeOut();
      $("#resume_day_header").css("display", "none").hide().fadeOut();
    } else {
      $("#initial_payment_date_div").css("display", "flex").hide().fadeIn();
      $("#default_collection_date").css("display", "flex").hide().fadeIn();
      $("#update_collection_date").css("display", "flex").hide().fadeIn();
      $("#resume_day_header").css("display", "flex").hide().fadeIn();
    }
  }
}
