import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        params: Object
    }

    initialize() {

        (function(d,t) {
            var BASE_URL="https://csr-chat.zing.gg";
            var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
            g.src=BASE_URL+"/packs/js/sdk.js";
            g.id = "chat_script";
            g.defer = true;
            g.async = true;
            s.parentNode.insertBefore(g,s);
            g.onload=function(){
                window.chatwootSDK.run({
                    websiteToken: 'jo5QRDnq4tv4XGaKB7gzy9Rs',
                    baseUrl: BASE_URL
                })
            }
        })(document,"script");

        document.addEventListener("turbo:load", function() {
            if(window.$chatwoot !=null && document.getElementById('chatwoot_live_chat_widget') == null) {
                $("#chat_script").remove();
                window.$chatwoot = null;

                (function(d,t) {
                    var BASE_URL="https://csr-chat.zing.gg";
                    var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
                    g.src=BASE_URL+"/packs/js/sdk.js";
                    g.id = "chat_script";
                    g.defer = true;
                    g.async = true;
                    s.parentNode.insertBefore(g,s);
                    g.onload=function(){
                        window.chatwootSDK.run({
                            websiteToken: 'jo5QRDnq4tv4XGaKB7gzy9Rs',
                            baseUrl: BASE_URL
                        })
                    }
                })(document,"script");
            } else if (window.$chatwoot ==null && document.getElementById('chatwoot_live_chat_widget') == null) {
                (function(d,t) {
                    var BASE_URL="https://csr-chat.zing.gg";
                    var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
                    g.src=BASE_URL+"/packs/js/sdk.js";
                    g.id = "chat_script";
                    g.defer = true;
                    g.async = true;
                    s.parentNode.insertBefore(g,s);
                    g.onload=function(){
                        window.chatwootSDK.run({
                            websiteToken: 'jo5QRDnq4tv4XGaKB7gzy9Rs',
                            baseUrl: BASE_URL
                        })
                    }
                })(document,"script");
            }
        });

        if (this.paramsValue && this.paramsValue.id){
            let id = this.paramsValue.id.toString()
            let userParams = this.paramsValue
            window.addEventListener("chatwoot:ready", function () {
                window.$chatwoot.setUser(id, {
                    name: userParams.name, // Name of the user
                    email: userParams.email, // Email of the user
                    description: userParams.description, // description about the user
                    company_name: userParams.company_name, // company name
                });

            });
        }
    }
}