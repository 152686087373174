import {Controller} from "@hotwired/stimulus"
import $ from "jquery";

export default class extends Controller {
    static targets = ["scheduledPayments"]
    static values = {
        orderId: Number,
        hasDeclined: Boolean
    }

    initialize() {
        this.index = 0
        this.selectedItems = []
    }

    connect() {
        if (this.hasDeclinedValue){
            document.getElementById("order_with_rejected_payments").style.display = '';
        }
    }


    refreshSelected(event) {
        var items = []
        this.scheduledPaymentsTargets.forEach(function (v) {
            if (v.checked) {
                // console.log(v.value)
                items.push(v.value)
                // console.log(items.length)
            }
        });
        //
        this.selectedItems = items
        this.refreshButtonsState()
    }

    cleanSelections(event) {
        this.selectedItems = []
        this.refreshButtonsState()
    }

    refreshButtonsState() {
        if (this.selectedItems.length == 0) {
            document.getElementById("skipSDPaymentBtn").disabled = true;
            document.getElementById("rescheduleSDPaymentBtn").disabled = true;
            document.getElementById("mergeSDPaymentBtn").disabled = true;
            document.getElementById("retrySDPaymentBtn").disabled = true;
            document.getElementById("executeNowSDPaymentBtn").disabled = true;

            // document.getElementById("addRetrySDPaymentBtn").disabled = true;
        } else if (this.selectedItems.length == 1) {
            document.getElementById("skipSDPaymentBtn").disabled = false;
            document.getElementById("rescheduleSDPaymentBtn").disabled = false;
            document.getElementById("mergeSDPaymentBtn").disabled = true;
            this.retrySDBtnState()
            this.executeNowSDBtnState()
        } else if (this.selectedItems.length > 1) {
            document.getElementById("skipSDPaymentBtn").disabled = false;
            document.getElementById("rescheduleSDPaymentBtn").disabled = true;
            document.getElementById("mergeSDPaymentBtn").disabled = false;
            document.getElementById("retrySDPaymentBtn").disabled = true;
            document.getElementById("executeNowSDPaymentBtn").disabled = true;
            // document.getElementById("addRetrySDPaymentBtn").disabled = true;
        }
        return this.scheduledPaymentsTargets.filter(checkbox => checkbox.checked)
    }

    retrySDBtnState() {
        if (this.selectedItems.length == 1 && !this.hasDeclinedValue) {
            var sdRow = document.getElementById("manageSDPayment-" + this.selectedItems[0])
            if (sdRow.dataset.sdstatus == 'failed') {
                document.getElementById("retrySDPaymentBtn").disabled = false;
            } else {
                document.getElementById("retrySDPaymentBtn").disabled = true;
            }
        } else {
            document.getElementById("retrySDPaymentBtn").disabled = true;
        }
    }

    executeNowSDBtnState() {
        if (this.selectedItems.length == 1 && !this.hasDeclinedValue) {
            var sdRow = document.getElementById("manageSDPayment-" + this.selectedItems[0])
            if (sdRow.dataset.sdstatus == 'scheduled') {
                document.getElementById("executeNowSDPaymentBtn").disabled = false;
            } else {
                document.getElementById("executeNowSDPaymentBtn").disabled = true;
            }
        } else {
            document.getElementById("executeNowSDPaymentBtn").disabled = true;
        }
    }

    skipSDPaymentBtnPressed(event) {
        event.preventDefault()

        $.ajax({
            type: "get",
            dataType: 'script',
            url: '/orders/' + this.orderIdValue + '/manage_scheduled_payments/skip_confirm',
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            data: `scheduled_payments=${this.selectedItems}`,
            error: function (data) {
                toastr["error"]("An error has occurred, please review the data and try again.")
            }
        })
    }

    rescheduleSDPaymentBtnPressed(event) {
        event.preventDefault()

        $.ajax({
            type: "get",
            dataType: 'script',
            url: `/scheduled_payments/${this.selectedItems[0]}/edit`,
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            error: function (data) {
                toastr["error"]("An error has occurred, please review the data and try again.")
            }
        })

        this.cleanSelections()
    }

    mergeSDPaymentBtnPressed(event) {
        event.preventDefault()

        $.ajax({
            type: "post",
            dataType: 'script',
            url: `/orders/${this.orderIdValue}/manage_scheduled_payments/merge.js`,
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            data: `scheduled_payments=${this.selectedItems}`,
            error: function (data) {
                toastr["error"]("An error has occurred, please review the data and try again.")
            }
        })

        this.cleanSelections()
    }

    retrySDPaymentBtnPressed(event) {
        event.preventDefault()

        $.ajax({
            type: "get",
            dataType: 'script',
            url: `/scheduled_payment/${this.selectedItems[0]}/confirm_retry_now`,
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            error: function (data) {
                toastr["error"]("An error has occurred, please review the data and try again.")
            }
        })
    }

    executeNowSDPaymentBtnPressed(event) {
        event.preventDefault()

        $.ajax({
            type: "get",
            dataType: 'script',
            url: `/scheduled_payment/${this.selectedItems[0]}/confirm_retry_now`,
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            error: function (data) {
                toastr["error"]("An error has occurred, please review the data and try again.")
            }
        })
    }

}
