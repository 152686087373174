
    $(function () {

        // Daterangepicker
        $(".daterange").daterangepicker({
            opens: "left",
            autoUpdateInput: true,
            locale: {
                format: "DD/MM/YYYY",
                cancelLabel: 'Clear'
            }
        });

        $('input[name="order[daterange]"]').daterangepicker({
            autoUpdateInput: false,
            locale: {
                format: "DD/MM/YY",
                cancelLabel: 'Clear'
            }
        });


        $('.scheduled_at_daterange').daterangepicker({autoUpdateInput: false}, (from_date, to_date) => {
            $('.scheduled_at_daterange').val(from_date.format('DD/MM/YYYY') + ' - ' + to_date.format('DD/MM/YYYY'));
        });

        $('.executed_at_daterange').daterangepicker({autoUpdateInput: false}, (from_date, to_date) => {
            $('.executed_at_daterange').val(from_date.format('DD/MM/YYYY') + ' - ' + to_date.format('DD/MM/YYYY'));
        });

        $('.executed_at_daterange_past').daterangepicker(
            {
                maxDate: moment(),
                startDate: moment().subtract(1, "month"),
                endDate: moment(),
                cancelLabel: 'Clear',
                autoUpdateInput: false,
                ranges: {
                    "Today": [moment(), moment()],
                    "Yesterday": [moment().subtract(1, "days"), moment().subtract(1, "days")],
                    "Last 7 Days": [moment().subtract(6, "days"), moment()],
                    "Last 30 Days": [moment().subtract(29, "days"), moment()],
                    "This Month": [moment().startOf("month"), moment().endOf("month")],
                    "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")]
                }
            }, (from_date, to_date) => {
                $('.executed_at_daterange_past').val(from_date.format('DD/MM/YYYY') + ' - ' + to_date.format('DD/MM/YYYY'));
            });


        $('.sp_date_range').on('apply.daterangepicker', function (ev, picker) {
            $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
        });

        $('.sp_date_range').on('cancel.daterangepicker', function (ev, picker) {
            $(this).val('');
        });

        $(".sp_date_range").daterangepicker({
            "showDropdowns": true,
            singleDatePicker: false,
            timePicker: false,
            opens: "left",
            timePicker24Hour: false,
            startDate: moment(),
            endDate: moment().add(1, 'month'),
            minDate: moment(),
            autoUpdateInput: false,
            ranges: {
                "Today": [moment(), moment()],
                "Next 7 Days": [moment(), moment().add(7, "days")],
                "Next Month": [moment(), moment().add(1, "month")]
            },
            locale: {
                format: "DD/MM/YYYY",
                cancelLabel: 'Clear'
            }
        });


        $(".order_report_date_range").daterangepicker({
            "showDropdowns": true,
            singleDatePicker: false,
            timePicker: false,
            opens: "left",
            timePicker24Hour: false,
            startDate: moment().subtract(1, 'month'),
            endDate: moment(),
            maxDate: moment(),
            ranges: {
                "Today": [moment(), moment()],
                "Yesterday": [moment().subtract(1, "days"), moment().subtract(1, "days")],
                "Last 7 Days": [moment().subtract(6, "days"), moment()],
                "Last 30 Days": [moment().subtract(29, "days"), moment()]
            },
            autoUpdateInput: false,
            locale: {
                format: "DD/MM/YYYY",
                cancelLabel: 'Clear'
            }
        });



        $('.order_report_date_range').on('apply.daterangepicker', function (ev, picker) {
            $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
        });

        $('.order_report_date_range').on('cancel.daterangepicker', function (ev, picker) {
            $(this).val('');
        });


        $('input[name="order[daterange]"]').on('apply.daterangepicker', function (ev, picker) {
            $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
        });

        $('input[name="order[daterange]"]').on('cancel.daterangepicker', function (ev, picker) {
            $(this).val('');
        });


        $(".datetimes").daterangepicker({
            singleDatePicker: true,
            timePicker: true,
            opens: "left",
            timePicker24Hour: true,
            minDate: moment().startOf("day").add(31, "hour"),
            locale: {
                format: "DD/MM/YYYY HH:mm"
            }
        });


        $(".datesingle").daterangepicker({
            singleDatePicker: true,
            showDropdowns: true
        });
        var start = moment().subtract(29, "days");
        var end = moment();

        function cb(start, end) {
            $("#reportrange span").html(start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY"));
        }

        $("#reportrange").daterangepicker({
            startDate: start,
            endDate: end,
            ranges: {
                "Today": [moment(), moment()],
                "Yesterday": [moment().subtract(1, "days"), moment().subtract(1, "days")],
                "Last 7 Days": [moment().subtract(6, "days"), moment()],
                "Last 30 Days": [moment().subtract(29, "days"), moment()],
                "This Month": [moment().startOf("month"), moment().endOf("month")],
                "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")]
            }
        }, cb);
        cb(start, end);
    })
