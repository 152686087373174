import { Controller } from "@hotwired/stimulus"
import $ from "jquery";

export default class extends Controller {
    static targets = [
        "durationUnit", "scheduledInterval", "productType", "collectionInterval",
        "scheduledDurationNumber", "productDiscountSelect", "productPrice", "initialPaymentAmount",
        "productTaxSelect", "lookPrice", "feeForInitialOnly", "discountForInitialOnly", "productInitial",
        "productRecurring", "productSubtotal", "productTaxableAmount", "productTaxAmount", "productTotalToPay",
        "lockPaymentConfig", "recurringPaymentAmountInput", "productStatus"
    ]

    connect() {
    }

    initialize() {
        this.productTypeUpdated();
        this.collectionIntervalUpdated();
        this.recalculateOrderTotals();
        this.initProductGroupSelect();
        this.initProductUnitSelect();
        this.initCurrencySelect();
        this.initDiscountSelect();
        this.initFeeSelect();
        this.initTaxSelect();
        this.initScheduleDurationSelect();
        this.initScheduleDurationUnitSelect();
        this.initCollectionIntervalSelect()

        var AmountInput = new Cleave('.amount-input', {
            numeral: true,
            delimiter: '',
            onlyPositive: true
            // numeralThousandsGroupStyle: 'thousand'
        });
    }

    initProductGroupSelect() {
        const dropdownParent = $("#product_product_group_id").parent(".form-select");
        $("#product_product_group_id").select2({
            width: "100%",
            dropdownParent,
        });
    }

    initProductUnitSelect() {
        const dropdownParent = $("#product_product_unit_id").parent(".form-select");
        $("#product_product_unit_id").select2({
            width: "100%",
            dropdownParent,
        });
    }

    initCurrencySelect() {
        const dropdownParent = $("#product_currency").parent(".form-select");
        $("#product_currency").select2({
            placeholder: "--",
            width: "100%",
            dropdownParent,
        });
    }

    initDiscountSelect() {
        const dropdownParent = $("#product_product_discount_id").parent(".form-select");
        $("#product_product_discount_id").select2({
            width: "100%",
            dropdownParent,
        })
        .on("select2:select", function (e) {
            this.discountSelectUpdated();
        }.bind(this));
    }

    initFeeSelect() {
        const dropdownParent = $("#product_product_fee_id").parent(".form-select");
        $("#product_product_fee_id").select2({
            width: "100%",
            dropdownParent,
        })
        .on("select2:select", function (e) {
            this.productFeeUpdated();
        }.bind(this));
    }

    initTaxSelect() {
        const dropdownParent = $("#product_product_tax_id").parent(".form-select");
        $("#product_product_tax_id").select2({
            width: "100%",
            dropdownParent,
        })
        .on("select2:select", function (e) {
            this.recalculateOrderTotals();
        }.bind(this));
    }

    initScheduleDurationSelect() {
        const dropdownParent = $("#scheduleDurationSelect").parent(".form-select");
        $("#scheduleDurationSelect").select2({
            placeholder: "--",
            width: "100%",
            dropdownParent,
        })
        .on("select2:select", function (e) {
            const scheduledDurationSelectedVal = e.params.data.id;
            this.scheduledDurationRadioUpdated(scheduledDurationSelectedVal);
        }.bind(this));
    }

    initScheduleDurationUnitSelect() {
        const dropdownParent = $("#product_scheduled_duration_unit").parent(".form-select");
        $("#product_scheduled_duration_unit").select2({
            placeholder: "--",
            width: "100%",
            dropdownParent,
        });
    }

    initCollectionIntervalSelect() {
        const dropdownParent = $("#collection_interval").parent(".form-select");
        $("#collection_interval").select2({
            placeholder: "--",
            width: "100%",
            dropdownParent,
        })
        .on("select2:select", function (e) {
            this.collectionIntervalUpdated();
        }.bind(this));
    }

    scheduledDurationRadioUpdated(scheduledDurationSelectedVal) {
        if(this.scheduledDurationNumberTarget.value > 3){
            scheduledDurationSelectedVal = 'CUSTOM';
        }

        if (scheduledDurationSelectedVal === '1') {
            this.scheduledDurationNumberTarget.value = 1;
        } else if (scheduledDurationSelectedVal === '2') {
            this.scheduledDurationNumberTarget.value = 2;
        } else if (scheduledDurationSelectedVal === '3') {
            this.scheduledDurationNumberTarget.value = 3;
        } else if (scheduledDurationSelectedVal === 'CUSTOM') {
            if (!(parseFloat(this.scheduledDurationNumberTarget.value) > 0)) {
                this.scheduledDurationNumberTarget.value = 1;
            }
            document.getElementById('scheduleDurationRadio_custom').style.display = "block";
            document.getElementById('scheduleDurationRadio_select').style.display = "none";
        }
        this.recalculateOrderTotals()
        // this.updateSummaryInfo()
    }

    discountSelectUpdated() {
        this.recalculateOrderTotals()
    }

    lockPaymentConfigUpdated(){
        if (this.lockPaymentConfigTarget.checked) {
            this.lookPriceTarget.checked = true
        }
    }

    recalculateDiscount() {
        let select = this.productDiscountSelectTarget
        let option = select.options[select.selectedIndex];
        let percentage = parseFloat(option.dataset.percentage)
        let amount = parseFloat(option.dataset.amount)
        if (select.value != null && parseFloat(percentage) > 0) {
            if (this.discountForInitialOnlyTarget.checked) {
                this.discountAmount = parseFloat(0)
                this.initialDiscountAmount = parseFloat(this.productPrice * (percentage / 100))
            } else {
                this.discountAmount = parseFloat(this.productPrice * (percentage / 100))
                this.initialDiscountAmount = parseFloat(this.productPrice * (percentage / 100))
            }
        } else if (select.value != null && amount > 0) {
            if (this.discountForInitialOnlyTarget.checked) {
                this.discountAmount = parseFloat(0)
                this.initialDiscountAmount = parseFloat(amount)
            } else {
                this.discountAmount = parseFloat(amount)
                this.initialDiscountAmount = parseFloat(amount)
            }
        } else {
            this.discountAmount = parseFloat(0)
            this.initialDiscountAmount = parseFloat(0)
        }
    }

    taxSelectUpdated() {
        let percentage = parseFloat($('#product_product_tax_id :selected').data('percentage')) || 0
        if (percentage > 0) {
            this.productPriceWithoutVat = parseFloat((this.productPrice / (100 + parseFloat(percentage))) * 100)
            this.taxAmount = parseFloat(this.productPrice - this.productPriceWithoutVat)
            this.taxPercentage = percentage
        } else {
            this.taxAmount = parseFloat(0)
            this.productPriceWithoutVat = parseFloat(this.productPrice)
            this.taxPercentage = 0
        }
    }

    setProductPrice() {
        if (!isNaN(parseFloat(this.productPriceTarget.value))) {
            this.productPrice = parseFloat(this.productPriceTarget.value) || 0
        } else {
            this.productPrice = 0
        }
    }

    setFeePrice() {
        let selectedFee = $("#product_product_fee_id option:selected")
        let feeAmount = parseFloat(selectedFee.data('amount')) || 0

        if (this.feeForInitialOnlyTarget.checked && feeAmount > 0) {
            this.feeAmount = parseFloat(0)
            this.initialFeeAmount = parseFloat(feeAmount)
        } else if (!this.feeForInitialOnlyTarget.checked && feeAmount > 0) {
            this.feeAmount = parseFloat(feeAmount)
            this.initialFeeAmount = parseFloat(feeAmount)
        } else {
            this.feeAmount = parseFloat(0)
            this.initialFeeAmount = parseFloat(0)
        }
        // this.recalculateOrderTotals()
    }

    productFeeUpdated() {
        this.recalculateOrderTotals()
    }

    initialPaymentAmountUpdated() {
        this.recalculateOrderTotals()
    }

    feeForInitialOnlyUpdated() {
        this.recalculateOrderTotals()
    }

    discountForInitialOnlyUpdated() {
        this.recalculateOrderTotals()
    }

    recalculateOrderTotals() {
        // console.log('recalculating totals')
        this.setProductPrice()
        this.setFeePrice()
        this.taxSelectUpdated()
        this.recalculateDiscount()

        // console.log(this.productType)
        if (this.productType === '' || this.productType === 'single_payment') {
            this.recurringAmount = parseFloat('0')
            this.subTotal = parseFloat(this.productPrice - this.discountAmount + this.feeAmount)
            this.totalToBePaid = this.subTotal
            this.initialAmount = parseFloat(this.totalToBePaid)
            this.totalTaxableAmount = (this.subTotal / (100 + parseFloat(this.taxPercentage))) * 100
            this.totalTaxAmount = (this.subTotal - this.totalTaxableAmount)
        } else if (this.productType === 'instalment') {
            this.subTotal = parseFloat(this.productPrice - this.discountAmount + this.feeAmount)
            this.totalToBePaid = parseFloat(this.productPrice - this.discountAmount + this.feeAmount)
            this.initialAmount = (parseFloat(this.initialPaymentAmountTarget.value) || 0)
            this.recurringAmount = parseFloat((this.subTotal - this.initialAmount) / (parseInt(this.scheduledDurationNumberTarget.value) || 1))
            this.totalTaxableAmount = (this.subTotal / (100 + parseFloat(this.taxPercentage))) * 100
            this.totalTaxAmount = (this.subTotal - this.totalTaxableAmount)
        } else if (this.productType === 'subscription') {

            this.initialAmount = parseFloat(this.productPrice + this.initialFeeAmount - this.initialDiscountAmount)
            this.recurringAmount = parseFloat(this.productPrice + this.feeAmount - this.discountAmount)
            this.subTotal = parseFloat(this.initialAmount)
            this.totalToBePaid = parseFloat(this.initialAmount)
            this.initialPaymentAmountTarget.value = this.initialAmount.toFixed(2)
            this.totalTaxableAmount = (this.subTotal / (100 + parseFloat(this.taxPercentage))) * 100
            this.totalTaxAmount = (this.subTotal - this.totalTaxableAmount)
            this.recurringTotalTaxableAmount = (this.recurringAmount / (100 + parseFloat(this.taxPercentage))) * 100
            this.recurringTotalTaxAmount = (this.recurringAmount - this.recurringTotalTaxableAmount)
            this.recurringPaymentAmountInputTarget.value = this.recurringAmount.toFixed(2)

        } else if (this.productType === 'metered') {
            this.totalToBePaid = parseFloat(this.feeAmount + this.productPrice - this.discountAmount)
            this.initialAmount = parseFloat(this.totalToBePaid)
            this.recurringAmount = parseFloat(0)
            this.subTotal = parseFloat(this.feeAmount + this.productPrice - this.discountAmount)
            this.totalTaxableAmount = (this.subTotal / (100 + parseFloat(this.taxPercentage))) * 100
            this.totalTaxAmount = (this.subTotal - this.totalTaxableAmount)
            this.recurringPaymentAmountInputTarget.value = this.recurringAmount.toFixed(2)
        }


        this.showTotals()
    }

    showTotals() {
        if (this.productType === '' || this.productType === 'single_payment') {
            this.hideInputs('instalmentContainers')
            this.hideInputs('recurringContainers')
            this.showInputs('oneOffContainers')
            document.getElementById('totalsSummaryDiscount').innerHTML = this.getFormattedCurrency(parseFloat(-Math.abs(this.discountAmount)).toFixed(2))
            //document.getElementById('totalsSummarySubTotal').innerHTML = this.subTotal.toFixed(2)
            document.getElementById('totalsInitialSummaryDiscount').innerHTML = this.getFormattedCurrency(parseFloat(-Math.abs(this.initialDiscountAmount)).toFixed(2))
            document.getElementById('totalsSummaryFee').innerHTML = this.getFormattedCurrency(this.feeAmount.toFixed(2))
            document.getElementById('totalsSummaryInitialAmount').innerHTML = this.getFormattedCurrency(this.initialAmount.toFixed(2))
            document.getElementById('totalsSummaryRecurringAmount').innerHTML = this.getFormattedCurrency(this.recurringAmount.toFixed(2))
            //document.getElementById('totalsSummaryTotalToPay').innerHTML = this.initialAmount.toFixed(2)
            document.getElementById('totalsSummaryTaxableAmount').innerHTML = this.getFormattedCurrency(this.totalTaxableAmount.toFixed(2))
            document.getElementById('totalsSummaryTaxAmount').innerHTML = this.getFormattedCurrency(this.totalTaxAmount.toFixed(2))

            document.getElementById('totalsSummarySubTotal').innerHTML = this.getFormattedCurrency(this.subTotal.toFixed(2))
            document.getElementById('totalsSummaryPaymentAmount').innerHTML = this.getFormattedCurrency(this.totalToBePaid.toFixed(2))


            document.getElementById('totalsSummarySubTotalTr').style.display = ''
            document.getElementById('totalsSummaryPaymentAmountTr').style.display = ''
            document.getElementById('totalsSummaryProductPriceTr').style.display = ''

            document.getElementById('totalsSummaryProductPriceRTr').style.display = 'none'
            document.getElementById('totalsSummaryBalanceTr').style.display = 'none'
            document.getElementById('totalsSummarySpitByTr').style.display = 'none'
            document.getElementById('totalsSummaryInstalmentAmountTr').style.display = 'none'
            document.getElementById('totalsSummaryDepositAmountTr').style.display = 'none'
            document.getElementById('totalsSummaryInstalmentInitialTr').style.display = 'none'
            document.getElementById('totalsSummaryInitialAmountTr').style.display = 'none'
            document.getElementById('totalsSummaryRecurringAmountTr').style.display = 'none'
            document.getElementById('totalsInitialSummaryDiscountTr').style.display = 'none'
            document.getElementById('totalsInitialSummaryFeeTr').style.display = 'none'
            document.getElementById('totalsSummaryRecurringTaxableAmountTr').style.display = 'none'
            document.getElementById('totalsSummaryRecurringTaxAmountTr').style.display = 'none'

        } else if (this.productType === 'instalment') {
            this.hideInputs('recurringContainers')
            this.hideInputs('oneOffContainers')
            this.showInputs('instalmentContainers')

            //document.getElementById('totalsSummarySubTotal').innerHTML = this.subTotal.toFixed(2)
            document.getElementById('totalsSummarySubTotal').innerHTML = this.getFormattedCurrency(this.subTotal.toFixed(2))
            document.getElementById('totalsSummaryDiscount').innerHTML = this.getFormattedCurrency(parseFloat(-Math.abs(this.discountAmount)).toFixed(2))
            document.getElementById('totalsSummaryFee').innerHTML = this.getFormattedCurrency(this.feeAmount.toFixed(2))
            document.getElementById('totalsSummaryDepositAmount').innerHTML = this.getFormattedCurrency(this.initialAmount.toFixed(2))
            document.getElementById('totalsSummaryInstalmentAmount').innerHTML = this.getFormattedCurrency(this.recurringAmount.toFixed(2))
            document.getElementById('totalsSummaryBalance').innerHTML = this.getFormattedCurrency((this.subTotal - this.initialAmount).toFixed(2))
            document.getElementById('totalsSummarySpitBy').innerHTML = (parseInt(this.scheduledDurationNumberTarget.value) || 1)
            //document.getElementById('totalsSummaryTotalToPay').innerHTML = this.initialAmount.toFixed(2)
            document.getElementById('totalsSummaryTaxableAmount').innerHTML = this.getFormattedCurrency(this.totalTaxableAmount.toFixed(2))
            document.getElementById('totalsSummaryTaxAmount').innerHTML = this.getFormattedCurrency(this.totalTaxAmount.toFixed(2))
            document.getElementById('totalsSummaryDepositAmount').innerHTML = this.getFormattedCurrency(this.initialAmount.toFixed(2))
            document.getElementById('totalsSummaryInstalmentInitial').innerHTML = this.getFormattedCurrency(this.initialAmount.toFixed(2))

            document.getElementById('balance_amount_input').value = this.getFormattedCurrency((this.subTotal - this.initialAmount).toFixed(2))
            document.getElementById('instalment_amount_input').value = this.getFormattedCurrency(this.recurringAmount.toFixed(2))

            document.getElementById('totalsSummaryDepositAmountTr').style.display = ''
            document.getElementById('totalsSummaryRecurringAmountTr').style.display = ''
            document.getElementById('totalsSummaryBalanceTr').style.display = ''
            document.getElementById('totalsSummarySpitByTr').style.display = ''
            document.getElementById('totalsSummaryInstalmentAmountTr').style.display = ''
            document.getElementById('totalsSummaryInstalmentInitialTr').style.display = ''
            document.getElementById('totalsSummaryProductPriceTr').style.display = ''

            document.getElementById('totalsSummaryProductPriceRTr').style.display = 'none'
            document.getElementById('totalsSummaryRecurringAmountTr').style.display = 'none'
            document.getElementById('totalsSummaryPaymentAmountTr').style.display = 'none'
            document.getElementById('totalsSummaryInitialAmountTr').style.display = 'none'
            document.getElementById('totalsInitialSummaryDiscountTr').style.display = 'none'
            document.getElementById('totalsInitialSummaryFeeTr').style.display = 'none'
            document.getElementById('totalsSummaryRecurringTaxableAmountTr').style.display = 'none'
            document.getElementById('totalsSummaryRecurringTaxAmountTr').style.display = 'none'
        } else if (this.productType === 'subscription') {
            this.hideInputs('instalmentContainers')
            this.hideInputs('oneOffContainers')
            this.showInputs('recurringContainers')

            //document.getElementById('totalsSummarySubTotal').innerHTML = this.subTotal.toFixed(2)
            document.getElementById('totalsInitialSummaryFee').innerHTML = this.getFormattedCurrency(this.initialFeeAmount.toFixed(2))
            document.getElementById('totalsInitialSummaryDiscount').innerHTML = this.getFormattedCurrency(parseFloat(-Math.abs(this.initialDiscountAmount)).toFixed(2))
            document.getElementById('totalsSummaryDiscount').innerHTML = this.getFormattedCurrency(parseFloat(-Math.abs(this.discountAmount)).toFixed(2))
            document.getElementById('totalsSummaryFee').innerHTML = this.getFormattedCurrency(this.feeAmount.toFixed(2))
            document.getElementById('totalsSummaryInitialAmount').innerHTML = this.getFormattedCurrency(this.initialAmount.toFixed(2))
            document.getElementById('totalsSummaryRecurringAmount').innerHTML = this.getFormattedCurrency(this.recurringAmount.toFixed(2))
            document.getElementById('totalsSummaryProductPriceR').innerHTML = this.getFormattedCurrency(this.productPrice.toFixed(2))
            //document.getElementById('totalsSummaryTotalToPay').innerHTML = this.initialAmount.toFixed(2)

            document.getElementById('totalsSummaryTaxableAmount').innerHTML = this.getFormattedCurrency(this.totalTaxableAmount.toFixed(2))
            document.getElementById('totalsSummaryTaxAmount').innerHTML = this.getFormattedCurrency(this.totalTaxAmount.toFixed(2))
            document.getElementById('totalsSummaryRecurringTaxableAmount').innerHTML = this.getFormattedCurrency(this.recurringTotalTaxableAmount.toFixed(2))
            document.getElementById('totalsSummaryRecurringTaxAmount').innerHTML = this.getFormattedCurrency(this.recurringTotalTaxAmount.toFixed(2))

            document.getElementById('totalsSummaryProductPriceRTr').style.display = ''
            document.getElementById('totalsSummaryInstalmentInitialTr').style.display = ''
            document.getElementById('totalsSummaryRecurringAmountTr').style.display = ''
            document.getElementById('totalsSummaryInitialAmountTr').style.display = ''
            document.getElementById('totalsSummaryRecurringAmountTr').style.display = ''
            document.getElementById('totalsSummaryRecurringTaxableAmountTr').style.display = ''
            document.getElementById('totalsSummaryRecurringTaxAmountTr').style.display = ''
            document.getElementById('totalsSummaryFeeTr').style.display = ''


            document.getElementById('totalsSummaryPaymentAmountTr').style.display = 'none'
            document.getElementById('totalsSummaryProductPriceTr').style.display = 'none'
            document.getElementById('totalsSummaryDepositAmountTr').style.display = 'none'
            document.getElementById('totalsSummaryBalanceTr').style.display = 'none'
            document.getElementById('totalsSummarySpitByTr').style.display = 'none'
            document.getElementById('totalsSummaryInstalmentAmountTr').style.display = 'none'
            document.getElementById('totalsSummarySubTotalTr').style.display = 'none'


            document.getElementById('totalsSummaryBalanceTr').style.display = 'none'
            document.getElementById('totalsSummarySpitByTr').style.display = 'none'
            document.getElementById('totalsSummaryInstalmentAmountTr').style.display = 'none'
            document.getElementById('totalsSummaryDepositAmountTr').style.display = 'none'
            document.getElementById('totalsSummaryInstalmentInitialTr').style.display = 'none'

            if (this.initialDiscountAmount > 0) {
                document.getElementById('totalsInitialSummaryDiscountTr').style.display = ''
            } else {
                document.getElementById('totalsInitialSummaryDiscountTr').style.display = 'none'
            }

            if (this.initialFeeAmount > 0) {
                document.getElementById('totalsInitialSummaryFeeTr').style.display = ''
            } else {
                document.getElementById('totalsInitialSummaryFeeTr').style.display = 'none'
            }

        } else if (this.productType === 'metered') {
            document.getElementById('totalsSummaryDiscount').innerHTML = this.getFormattedCurrency(parseFloat(-Math.abs(this.discountAmount)).toFixed(2))
            document.getElementById('totalsSummaryFee').innerHTML = this.getFormattedCurrency(this.feeAmount.toFixed(2))
            document.getElementById('totalsSummarySubTotal').innerHTML = this.getFormattedCurrency(this.initialAmount.toFixed(2))
            document.getElementById('totalsSummaryPaymentAmount').innerHTML = this.getFormattedCurrency(this.initialAmount.toFixed(2))

            document.getElementById('totalsSummaryRecurringAmount').innerHTML = this.getFormattedCurrency(this.recurringAmount.toFixed(2))
            document.getElementById('totalsSummaryRecurringAmount').innerHTML = this.getFormattedCurrency(this.recurringAmount.toFixed(2))

            document.getElementById('totalsSummaryTaxableAmount').innerHTML = this.getFormattedCurrency(this.totalTaxableAmount.toFixed(2))
            document.getElementById('totalsSummaryTaxAmount').innerHTML = this.getFormattedCurrency(this.totalTaxAmount.toFixed(2))

            document.getElementById('totalsSummaryProductPriceTr').style.display = ''
            document.getElementById('totalsSummarySubTotalTr').style.display = ''

            document.getElementById('totalsSummaryRecurringTaxableAmountTr').style.display = 'none'
            document.getElementById('totalsSummaryRecurringTaxAmountTr').style.display = 'none'
            document.getElementById('totalsSummaryRecurringAmountTr').style.display = 'none'
            document.getElementById('totalsInitialSummaryDiscountTr').style.display = 'none'
            document.getElementById('totalsInitialSummaryFeeTr').style.display = 'none'
            document.getElementById('totalsSummaryProductPriceRTr').style.display = 'none'
            document.getElementById('totalsSummaryInitialAmountTr').style.display = 'none'
            document.getElementById('totalsSummaryDepositAmountTr').style.display = 'none'
            document.getElementById('totalsSummaryBalanceTr').style.display = 'none'
            document.getElementById('totalsSummarySpitByTr').style.display = 'none'
            document.getElementById('totalsSummaryInstalmentAmountTr').style.display = 'none'
            document.getElementById('totalsSummaryInstalmentInitialTr').style.display = 'none'
        }

        if (this.totalTaxAmount > 0 && this.taxPercentage > 0) {
            document.getElementById('totalsSummaryTaxAmountTr').style.display = ''
            document.getElementById('totalsSummaryTaxableAmountTr').style.display = ''
        } else {
            document.getElementById('totalsSummaryTaxAmountTr').style.display = 'none'
            document.getElementById('totalsSummaryTaxableAmountTr').style.display = 'none'
        }

        if (this.discountAmount > 0) {
            document.getElementById('totalsSummaryDiscountTr').style.display = ''
        } else {
            document.getElementById('totalsSummaryDiscountTr').style.display = 'none'
        }

        if (this.feeAmount > 0) {
            document.getElementById('totalsSummaryFeeTr').style.display = ''
        } else {
            document.getElementById('totalsSummaryFeeTr').style.display = 'none'
        }

        document.getElementById('totalsSummaryProductPrice').innerHTML = this.getFormattedCurrency(this.productPrice.toFixed(2))
    }

    productTypeUpdated(event=null) {

        this.productFeeUpdated()
        this.productType = $("input[name='product[product_type]']:checked").val();

        if (this.productType === 'subscription') {
            $("#collection_interval option[value='WEEK']").remove();
            $("#collection_interval option[value='28DAYS']").remove();
            $("#collection_interval option[value='FORTNIGHT']").remove();
            $("#collection_interval option[value='CUSTOM']").remove();

            $("#collection_interval option[value='YEAR']").text('Yearly');
            $("#collection_interval option[value='QUARTER']").text('Quarterly');
            $("#collection_interval option[value='MONTH']").text('Monthly');
        } else {
            if (event) {
                $("#collection_interval option").remove();

                let option = new Option("Week", "WEEK", false, false);
                $("#collection_interval").append(option).trigger('change');
                option = new Option("Fortnight", "FORTNIGHT", false, false);
                $("#collection_interval").append(option).trigger('change');
                option = new Option("Month", "MONTH", false, false);
                $("#collection_interval").append(option).trigger('change');
                option = new Option("Quarter", "QUARTER", false, false);
                $("#collection_interval").append(option).trigger('change');
                option = new Option("Year", "YEAR", false, false);
                $("#collection_interval").append(option).trigger('change');
                option = new Option("Custom", "CUSTOM", false, false);
                $("#collection_interval").append(option).trigger('change');

                $("#collection_interval option[value='YEAR']").text('Year');
                $("#collection_interval option[value='QUARTER']").text('Quarter');
                $("#collection_interval option[value='MONTH']").text('Month');
            }
        }

        if (event) {
            // Initialize Collection Interval
            $("#collection_interval").select2("destroy");
            this.initCollectionIntervalSelect();
            $("#collection_interval").val('MONTH').trigger("change");

            // Initialize Schedule Duration
            $("#scheduleDurationSelect").select2("destroy");
            this.initScheduleDurationSelect();
            $("#scheduleDurationSelect").val("1").trigger("change");

            // Initialize Schedule Duration Unit
            $("#product_scheduled_duration_unit").select2("destroy");
            this.initScheduleDurationUnitSelect();
            $("#product_scheduled_duration_unit").val("MONTH").trigger("change");

            // Initialize initial amount
            this.initialPaymentAmountTarget.value = 0;
        }

        const scheduledDurationSelectedVal = $("#scheduleDurationSelect").val();
        this.scheduledDurationRadioUpdated(scheduledDurationSelectedVal)

        if (this.productType === '' || this.productType === 'single_payment') {
            this.collectionIntervalTarget.value = ''
            this.scheduledIntervalTarget.value = ''
            this.scheduledDurationNumberTarget.value = ''
            document.querySelector('#totalsSummaryDiscountTr > th').innerHTML = 'Discount'
            document.querySelector('#totalsSummaryFeeTr > th').innerHTML = 'Fee'

            document.getElementById('scheduleDurationRadio_custom').style.display = "none";
            document.getElementById('collection_interval_container').style.display = 'none'
            document.getElementById('initial_payment_amount_container').style.display = 'none'
            document.getElementById('scheduleDurationRadio_custom').style.display = "none";
            document.getElementById('scheduleDurationRadio_select').style.display = "none";
            document.getElementById('scheduled_duration_number_container').style.display = "none";
            document.getElementById('recurring_payment_amount_container').style.display = "none";
            document.getElementById('scheduled_duration_container').style.display = "none";
            document.getElementById('instalment_amount_container').style.display = "none";
            document.getElementById('balance_amount_container').style.display = "none";

            this.feeForInitialOnlyTarget.checked = false
            this.discountForInitialOnlyTarget.checked = false
            document.getElementById('fee_for_initial_container').style.display = "none";
            document.getElementById('discount_for_initial_container').style.display = "none";
            document.getElementById('lockPaymentConfigContainer').style.display = "none";
        } else if (this.productType === 'instalment') {
            document.getElementById('instalment_amount_container').style.display = "";
            document.getElementById('balance_amount_container').style.display = "";
            document.getElementById('recurring_payment_amount_container').style.display = "none";
            document.getElementById('initialAmountSpan').innerHTML = 'Deposit Amount'
            document.getElementById('collection_interval_container').style.display = 'block'
            document.getElementById('initial_payment_amount_container').style.display = 'block'
            document.getElementById('scheduled_duration_number_container').style.display = "block";
            //document.querySelector('#totalsSummaryTotalToPayTr > th').innerHTML = 'Deposit Amount'
            document.querySelector('#totalsSummaryDiscountTr > th').innerHTML = 'Discounts'
            document.querySelector('#totalsSummaryFeeTr > th').innerHTML = 'Fees'
            this.initialPaymentAmountTarget.readOnly = false
            // this.initialPaymentAmountTarget.value = this.productPriceTarget.value
            this.feeForInitialOnlyTarget.checked = false
            this.discountForInitialOnlyTarget.checked = false
            document.getElementById('fee_for_initial_container').style.display = "none";
            document.getElementById('discount_for_initial_container').style.display = "none";
            // console.log(this.scheduledDurationNumberTarget.value)
            if (this.scheduledDurationNumberTarget.value === '') {
                // console.log('1 or blanks')
                this.scheduledDurationNumberTarget.value = 1
                $("#scheduleDurationRadio").val(this.scheduledDurationNumberTarget.value).trigger('change');
                document.getElementById('scheduleDurationRadio_custom').style.display = "none";
                document.getElementById('scheduleDurationRadio_select').style.display = "block";
            } else if (parseInt(this.scheduledDurationNumberTarget.value) <= 3) {
                $("#scheduleDurationRadio").val(this.scheduledDurationNumberTarget.value).trigger('change');
                document.getElementById('scheduleDurationRadio_custom').style.display = "none";
                document.getElementById('scheduleDurationRadio_select').style.display = "block";
            } else {
                $("#scheduleDurationRadio").val("CUSTOM").trigger('change');
                document.getElementById('scheduleDurationRadio_custom').style.display = "block";
                document.getElementById('scheduleDurationRadio_select').style.display = "none";
            }

            document.getElementById('lockPaymentConfigContainer').style.display = "flex";

        } else if (this.productType === 'subscription') {
            this.scheduledDurationNumberTarget.value = ''
            document.getElementById('initialAmountSpan').innerHTML = 'Initial Amount'
            this.initialPaymentAmountTarget.readOnly = true
            //document.querySelector('#totalsSummaryTotalToPayTr > th').innerHTML = 'Initial Amount'
            document.querySelector('#totalsSummaryDiscountTr > th').innerHTML = 'Recurring Discount'
            document.querySelector('#totalsSummaryFeeTr > th').innerHTML = 'Recurring Fee'

            document.getElementById('instalment_amount_container').style.display = "none";
            document.getElementById('balance_amount_container').style.display = "none";
            document.getElementById('scheduleDurationRadio_custom').style.display = "none";
            document.getElementById('collection_interval_container').style.display = ''
            document.getElementById('scheduled_duration_container').style.display = 'none'
            document.getElementById('initial_payment_amount_container').style.display = ''
            document.getElementById('recurring_payment_amount_container').style.display = "";

            document.getElementById('scheduleDurationRadio_custom').style.display = "none";
            document.getElementById('scheduleDurationRadio_select').style.display = "none";

            document.getElementById('scheduled_duration_number_container').style.display = "none";
            document.getElementById('fee_for_initial_container').style.display = "";
            document.getElementById('discount_for_initial_container').style.display = "";
            document.getElementById('lockPaymentConfigContainer').style.display = "flex";

            // this.feeForInitialOnlyTarget.checked = true
            // this.discountForInitialOnlyTarget.checked = true
        } else if (this.productType === 'metered') {
            document.getElementById('initialAmountSpan').innerHTML = 'Initial Amount'
            this.scheduledDurationNumberTarget.value = ''
            this.initialPaymentAmountTarget.value = ''
            $("#collection_interval").val('')
            //document.querySelector('#totalsSummaryTotalToPayTr > th').innerHTML = 'Initial Amount'
            document.querySelector('#totalsSummaryDiscountTr > th').innerHTML = 'Discounts'
            document.querySelector('#totalsSummaryFeeTr > th').innerHTML = 'Fees'

            document.getElementById('instalment_amount_container').style.display = "none";
            document.getElementById('balance_amount_container').style.display = "none";
            document.getElementById('scheduleDurationRadio_custom').style.display = "none";
            document.getElementById('collection_interval_container').style.display = 'none'
            document.getElementById('initial_payment_amount_container').style.display = 'none'

            document.getElementById('scheduled_duration_number_container').style.display = "none";
            document.getElementById('scheduleDurationRadio_custom').style.display = "none";
            document.getElementById('scheduleDurationRadio_select').style.display = "none";
            document.getElementById('recurring_payment_amount_container').style.display = "none";

            this.feeForInitialOnlyTarget.checked = false
            this.discountForInitialOnlyTarget.checked = false
            document.getElementById('fee_for_initial_container').style.display = "none";
            document.getElementById('discount_for_initial_container').style.display = "none";
            document.getElementById('lockPaymentConfigContainer').style.display = "none";
        }

        this.collectionIntervalUpdated()
        this.recalculateOrderTotals()
    }

    disableInputs(className) {
        let elements = document.getElementsByClassName(className)
        for (let i = 0; i < elements.length; i++) {
            elements[i].disabled = true;
        }
    }

    enableInputs(className) {
        let elements = document.getElementsByClassName(className)
        for (let i = 0; i < elements.length; i++) {
            elements[i].disabled = false;
        }
    }

    hideInputs(className) {
        let elements = document.getElementsByClassName(className)
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.display = 'none'
        }
    }

    showInputs(className) {
        let elements = document.getElementsByClassName(className)
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.display = 'inline';
        }
    }

    collectionIntervalUpdated(event) {
        if (this.productType === 'instalment' || this.productType === 'subscription') {
            if ($("#collection_interval").val() == null) {

                this.scheduledIntervalTarget.style.disabled = true
                this.durationUnitTarget.style.disabled = true

                $("#scheduled_duration_container").css("display", "none").fadeOut();

            } else if ($("#collection_interval").val() === 'MONTH') {
                this.scheduledIntervalTarget.style.disabled = false
                this.durationUnitTarget.style.disabled = false
                this.scheduledIntervalTarget.value = 1;
                this.durationUnitTarget.value = 'MONTH';

                $("#scheduled_duration_container").css("display", "none").fadeOut();

            } else if ($("#collection_interval").val() === 'YEAR') {
                this.scheduledIntervalTarget.style.disabled = false
                this.durationUnitTarget.style.disabled = false
                this.scheduledIntervalTarget.value = 1;
                this.durationUnitTarget.value = 'YEAR';


                $("#scheduled_duration_container").css("display", "none").fadeOut();

            } else if ($("#collection_interval").val() === 'WEEK') {
                this.scheduledIntervalTarget.style.disabled = false
                this.durationUnitTarget.style.disabled = false
                this.scheduledIntervalTarget.value = 1;
                this.durationUnitTarget.value = 'WEEK';


                $("#scheduled_duration_container").css("display", "none").fadeOut();

            } else if ($("#collection_interval").val() === '28DAYS') {
                this.scheduledIntervalTarget.style.disabled = false
                this.durationUnitTarget.style.disabled = false
                this.scheduledIntervalTarget.value = 28;
                this.durationUnitTarget.value = 'DAY';


                $("#scheduled_duration_container").css("display", "none").fadeOut();

            } else if ($("#collection_interval").val() === 'FORTNIGHT') {
                this.scheduledIntervalTarget.style.disabled = false
                this.durationUnitTarget.style.disabled = false
                this.scheduledIntervalTarget.value = 14;
                this.durationUnitTarget.value = 'DAY';


                $("#scheduled_duration_container").css("display", "none").fadeOut();

            } else if ($("#collection_interval").val() === 'QUARTER') {
                this.scheduledIntervalTarget.style.disabled = false
                this.durationUnitTarget.style.disabled = false
                this.scheduledIntervalTarget.value = 3;
                this.durationUnitTarget.value = 'MONTH';


                $("#scheduled_duration_container").css("display", "none").fadeOut();

            } else if ($("#collection_interval").val() === 'CUSTOM' && this.productType === 'instalment') {
                this.scheduledIntervalTarget.style.disabled = false
                this.durationUnitTarget.style.disabled = false

                $("#scheduled_duration_container").css("display", "inline").fadeIn();
                $("#collection_interval_container").css("display", "none").fadeOut();
            }
        } else {
            $("#scheduled_duration_container")
                .css("display", "none")
                .hide()
                .fadeOut();
        }
        this.recalculateOrderTotals()
        // this.updateSummaryInfo()
    }

    getFormattedCurrency(price) {
        let locale = 'en-GB';

        const formatString = Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return formatString.format(price);
    }

}