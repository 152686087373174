import consumer from "../channels/consumer"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["reports"]

    connect() {
        this.subscription = consumer.subscriptions.create(
            {
                channel: "CustomReportsChannel",
                channel_id: this.data.get("channelid"),
            },
            {
                connected: this._connected.bind(this),
                disconnected: this._disconnected.bind(this),
                received: this._received.bind(this),
            }
        );
    }

    _connected() {
    }

    _disconnected() {
    }

    _received(data) {
        const reportId = 'custom_report_' + data.report_id
        const element =  document.getElementById(reportId);

        if (typeof(element) != 'undefined' && element != null) {
            element.remove();
            document.getElementById('reportsContainer').insertAdjacentHTML('afterbegin', data.report_partial);
        } else {
            document.getElementById('reportsContainer').insertAdjacentHTML('afterbegin', data.report_partial);
        }
        document.getElementById(reportId).style.background = 'rgba(0, 0, 0, 0.075)';
    }
}
