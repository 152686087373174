import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        'verified3ds'
    ]

    connect() {
    }

    updatePaymentMethod(event) {
        $.ajax({
            url: this.verified3dsTarget.dataset.url,
            type: 'get',
        })
    }
}