import { Controller } from "@hotwired/stimulus"
import Cleave from "cleave.js";

export default class extends Controller {
    static targets = ["exchangeRadio", "manualRadio", "addPremium", "conversionPremium", "premiumToggle", "currencyPairInputBlock"]

    initialize() {
        this.updateExchangeRate()
        this.updateAddPremium()
        this.setDefault()

        $('.amount-input-decimal').toArray().forEach(function(field) {
            new Cleave(field, {
                numeral: true,
                delimiter: '',
                onlyPositive: true
            })})
    }

    setDefault(){
        if (this.exchangeRadioTarget.checked || this.manualRadioTarget.checked) {
            this.currencyPairInputBlockTarget.hidden = false
        } else {
            this.currencyPairInputBlockTarget.hidden = true
        }
    }

    togglePremium(){
        this.currencyPairInputBlockTarget.hidden = false

        if (this.exchangeRadioTarget.checked){
            this.showPremium()
        } else{
           this.hidePremium()
        }
    }

    showPremium(){
        this.premiumToggleTarget.hidden = false
        this.conversionPremiumTarget.disabled = true
        this.resetRates('custom-rate-inputs')
    }

    hidePremium(){
        this.premiumToggleTarget.hidden = true
        this.addPremiumTarget.checked = false
        this.conversionPremiumTarget.disabled = true
    }


    updateCustomRate(){
       if (this.exchangeRadioTarget.checked){
          this.calRate('custom-rate-inputs')
       }
    }

    calRate(className){
        let elements = document.getElementsByClassName(className)
        for (let i = 0; i < elements.length; i++) {
            console.log(elements[i].dataset)
            if (elements[i].dataset.skipPremium != "true"){
                elements[i].value = this.customPriceCal(parseFloat(this.conversionPremiumTarget.value).toFixed(2), parseFloat(elements[i].dataset.defaultRate).toFixed(2));
            }
        }
    }

    customPriceCal(per, total){
        return (((per/100) * total) + parseFloat(total)).toFixed(2)
    }

    updateExchangeRate() {
        this.togglePremium()
        if (this.exchangeRadioTarget.checked === true) {
            this.disableInputs('custom-rate-inputs')
            this.resetRates('custom-rate-inputs')
        } else if (this.manualRadioTarget.checked === true) {
            this.enableInputs('custom-rate-inputs')
            this.resetRates("custom-rate-inputs")
        }
    }

    setValues(className){
        let elements = document.getElementsByClassName(className)
        for (let i = 0; i < elements.length; i++) {
            elements[i].value = parseFloat(elements[i].getAttribute("value")).toFixed(2);
        }
    }

    updateAddPremium() {
        if (this.addPremiumTarget.checked == true) {
            this.conversionPremiumTarget.disabled = false
            this.updateCustomRate()
        } else {
            this.conversionPremiumTarget.disabled = true
            this.resetRates('custom-rate-inputs')
        }
    }


    resetRates(className) {
        let elements = document.getElementsByClassName(className)
        for (let i = 0; i < elements.length; i++) {

            if(this.manualRadioTarget.checked === true){
                elements[i].value = parseFloat(elements[i].dataset.savedRate).toFixed(2);
            } else {
                elements[i].value = parseFloat(elements[i].dataset.defaultRate).toFixed(2);
            }
        }
    }

    disableInputs(className) {
        let elements = document.getElementsByClassName(className)
        for (let i = 0; i < elements.length; i++) {
            elements[i].readOnly = true;
        }
    }

    enableInputs(className) {
        let elements = document.getElementsByClassName(className)
        for (let i = 0; i < elements.length; i++) {
            elements[i].readOnly = false;
        }
    }
}
