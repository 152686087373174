import {Controller} from "@hotwired/stimulus"
import $ from "jquery";

export default class extends Controller {
    static targets = [
        'productQty', 'productAmount', 'productDiscountSelect', 'discountDescription', 'discountAmount',
        'selectProductFeeId', 'feeAmount', 'feeDescription', 'productTaxSelect', 'taxAmount', 'subTotal',
        'discountForInitialOnly', 'feeForInitialOnly'
    ]

    static values = {
        newRecord: Boolean,
        productIndex: String,
        excludeIds: String,
        parentProductType: String,
        lastProduct: Boolean
    }

    connect() {
        this.productChanged = true;

        $(`#${this.productIndexValue}`).on('orderFormProductController.productSelected', function (event, id) {
            this.setProductDetailsFromID(id)
        }.bind(this));
        $(`#${this.productIndexValue}`).on('orderFormProductController.errorOnProductSelect', function () {
            this.errorOnProductSelect()
        }.bind(this));
        $(`#${this.productIndexValue}`).on('orderFormProductController.setProductVariables', function (event, data) {
            this.setProductVariables(data)
        }.bind(this));
        $(document).on('orderFormProductController.currencyUpdated', function (event, currency) {
            this.currencyUpdated(currency)
        }.bind(this));
        $(document).on('orderFormProductController.updateProductSelect2', function (event, data) {
            if (this.productIndexValue !== "product_0") {
                $(`#${this.productIndexValue}_product_id`).select2("destroy");

                const arrExcludeIDs = Object.keys(data.excludeIds).map(function(key){
                    return data.excludeIds[key];
                });

                this.initializeProductSelect2(arrExcludeIDs.join(), true);
            }
        }.bind(this));

        this.initializeProductSelect2(this.excludeIdsValue);
        this.initializeDiscountSelect2();
        this.initializeFeeSelect2();
        this.initializeTaxSelect2();
    }

    initialize() {
        this.recalculateOrderTotals();
    }

    currencyUpdated(currency) {
        if (typeof this.productConvertedPriceList != 'undefined') {
            this.productBrutPrice = parseFloat(this.productConvertedPriceList[currency])
            this.productAmountTarget.value = this.productBrutPrice.toFixed(2)
            this.recalculateOrderTotals()
        }
    }

    setProductDetailsFromID(id) {
        const thisPointer = this
        $.ajax({
            type: "GET",
            dataType: "json",
            url: '/channels/' + document.getElementById('orderForm').dataset.channel + '/products/' + id + '.json',
            data: {},
            success: function (repsonse) {
                $(`#${thisPointer.productIndexValue}`).trigger('orderFormProductController.setProductVariables', repsonse)
            },
            error: function (repsonse) {
                $(`#${thisPointer.productIndexValue}`).trigger('orderFormProductController.errorOnProductSelect')
            }
        })
    }

    errorOnProductSelect() {
        toastr.error('There was a problem with your network. Please refresh!')
    }

    setProductVariables(data) {
        this.productLockPricing = data.lock_price
        this.productLockPaymentConfig = data.lock_payment_config
        this.productCurrency = data.currency
        this.productPrice = parseFloat(data.price || 0)
        this.productCollectInitailPayment = data.form_collect_initial
        this.productInitialAmount = parseFloat(data.form_initial_payment || 0)
        this.productTotalInstallmentAmount = parseFloat(data.form_total_i_amount || 0)
        this.productConvertedPriceList = data.converted_price_list
        this.productDiscountID = data.product_discount_id
        this.productDiscountForIntitialOnly = data.discount_for_initial_payment_only
        this.productFeeId = data.product_fee_id
        this.productFeeForInitialOnly = data.fee_for_initial_payment_only
        this.productTaxId = data.product_tax_id
        this.productId = data.id
        this.productName = data.name
        this.productType = data.product_type
        this.prodcutSDDurationNuber = parseInt(data.form_s_duration_number || 1)
        this.prodcutSDDurationUnit = data.form_s_duration_unit
        this.prodcutSDInterval = data.form_s_interval
        this.prodcutColelctionInterval = data.collection_interval
        this.prodcutShortDesc = data.short_desc
        this.productUnscheduled = data.unscheduled
        this.productMonthlyAmount = data.monthly_amount

        this.discountForInitialOnlyTarget.value = this.productDiscountForIntitialOnly
        this.feeForInitialOnlyTarget.value = this.productFeeForInitialOnly

        this.productSelectUpdated();

        if (!this.productLockPricing) {
            $(`#${this.productIndexValue}_discount_by_id`).css('opacity', '')
            $(`#${this.productIndexValue}_discount_by_id`).css('pointer-events', '')
            $(`fieldset#${this.productIndexValue} #product_discounts_amount`).removeAttr('readonly')

            $(`#${this.productIndexValue}_fee_by_id`).css('opacity', '')
            $(`#${this.productIndexValue}_fee_by_id`).css('pointer-events', '')
            $(`fieldset#${this.productIndexValue} #order_product_fee_amount`).removeAttr('readonly')

            $(`#${this.productIndexValue}_tax_by_id`).css('opacity', '')
            $(`#${this.productIndexValue}_tax_by_id`).css('pointer-events', '')
            $(`fieldset#${this.productIndexValue} #order_products_amount`).removeAttr('readonly')
        } else {
            $(`#${this.productIndexValue}_discount_by_id`).css('opacity', '0.6')
            $(`#${this.productIndexValue}_discount_by_id`).css('pointer-events', 'none')
            $(`fieldset#${this.productIndexValue} #product_discounts_amount`).attr('readonly', 'readonly')

            $(`#${this.productIndexValue}_fee_by_id`).css('opacity', '0.6')
            $(`#${this.productIndexValue}_fee_by_id`).css('pointer-events', 'none')
            $(`fieldset#${this.productIndexValue} #order_product_fee_amount`).attr('readonly', 'readonly')

            $(`#${this.productIndexValue}_tax_by_id`).css('opacity', '0.6')
            $(`#${this.productIndexValue}_tax_by_id`).css('pointer-events', 'none')
            $(`fieldset#${this.productIndexValue} #order_products_amount`).attr('readonly', 'readonly')
        }
    }

    productSelectUpdated() {
        const currency = $("#order_currency option:selected").val()
        if (currency !== this.productCurrency) {
            toastr.error(`You order currency is different from product[${this.productName}] base currency`)
        }

        this.productBrutPrice = parseFloat(this.productConvertedPriceList[currency])
        this.productAmountTarget.value = this.productBrutPrice.toFixed(2)



        const orderId = $('#order_id').val();
        if (orderId === "") {
            this.productChanged = true;
        }

        if (this.productChanged) {
            this.productQtyTarget.value = 1;

            // Init discount
            this.productDiscountSelectTarget.disabled = false;
            $(`#${this.productIndexValue}_discount_by_id`).css('display', 'block');
            $(`#${this.productIndexValue}_discount_custom`).css('display', 'none');

            if (this.productDiscountID != null) {
                this.productDiscountSelectTarget.value = this.productDiscountID;
                $(`#${this.productIndexValue}_discount_id`).val(this.productDiscountID).trigger('change');
            } else {
                this.productDiscountSelectTarget.value = ''
                $(`#${this.productIndexValue}_discount_id`).val(null).trigger('change');
            }

            this.discountAmountTarget.value = parseFloat($(`#${this.productIndexValue}_discount_id :selected`).data('amount'));

            // Init fee
            this.selectProductFeeIdTarget.disabled = false;
            $(`#${this.productIndexValue}_fee_by_id`).css('display', 'block');
            $(`#${this.productIndexValue}_fee_custom`).css('display', 'none');

            if (this.productFeeId != null) {
                this.selectProductFeeIdTarget.value = this.productFeeId;
                $(`#${this.productIndexValue}_fee_id`).val(this.productFeeId).trigger('change');
                this.feeSelectUpdated();
            } else {
                this.selectProductFeeIdTarget.value = ''
                $(`#${this.productIndexValue}_fee_id`).val(null).trigger('change');
            }
            this.feeAmountTarget.value = $(`#${this.productIndexValue}_fee_id :selected`).data('amount');

            // Init Tax
            if (this.productTaxId != null) {
                this.productTaxSelectTarget.value = this.productTaxId
                $(`#${this.productIndexValue}_tax_id`).val(this.productTaxId).trigger('change');
            } else {
                this.productTaxSelectTarget.value = ''
                $(`#${this.productIndexValue}_tax_id`).val(null).trigger('change');
            }

            this.recalculateOrderTotals()
            $(document).trigger('orderFormController.productSelectUpdated',
                {
                    content: this,
                    identifier: this.productIndexValue,
                    isLastProduct: this.lastProductValue
                }
            )
        } else {
            const thisPointer = this;

            $.ajax({
                type: "GET",
                dataType: "json",
                url: `/orders/${orderId}/order_products.json`,
                data: `product_id=${this.productId}`,
                success: function (response) {
                    // Init Product Amount
                    this.productAmountTarget.value = parseFloat(response.amount).toFixed(2);

                    // Init discount
                    if (response.product_discount_id != null) {
                        this.productDiscountSelectTarget.value = response.product_discount_id;
                        $(`#${this.productIndexValue}_discount_id`).val(response.product_discount_id).trigger('change');

                        $(`#${this.productIndexValue}_discount_by_id`).css('display', 'block');
                        $(`#${this.productIndexValue}_discount_custom`).css('display', 'none');
                    } else {
                        this.productDiscountSelectTarget.value = '';
                        $(`#${this.productIndexValue}_discount_id`).val(null).trigger('change');

                        if (response.discount_amount != "") {
                            this.discountAmountTarget.value = parseFloat(response.discount_amount).toFixed(2);

                            $(`#${this.productIndexValue}_discount_by_id`).css('display', 'none');
                            $(`#${this.productIndexValue}_discount_custom`).css('display', 'block');
                        }
                    }

                    // Init fee
                    if (response.product_fee_id != null) {
                        this.selectProductFeeIdTarget.value = response.product_fee_id;
                        $(`#${this.productIndexValue}_fee_id`).val(response.product_fee_id).trigger('change');

                        $(`#${this.productIndexValue}_fee_by_id`).css('display', 'block');
                        $(`#${this.productIndexValue}_fee_custom`).css('display', 'none');
                    } else {
                        this.selectProductFeeIdTarget.value = '';
                        $(`#${this.productIndexValue}_fee_id`).val(null).trigger('change');
                        if (response.fee_amount !== "") {
                            this.feeAmountTarget.value = parseFloat(response.fee_amount).toFixed(2);

                            $(`#${this.productIndexValue}_fee_by_id`).css('display', 'none');
                            $(`#${this.productIndexValue}_fee_custom`).css('display', 'block');
                        }
                    }

                    // Init tax
                    if (response.product_tax_id != null) {
                        this.productTaxSelectTarget.value = response.product_tax_id;
                        $(`#${this.productIndexValue}_tax_id`).val(response.product_tax_id).trigger('change');
                    } else {
                        this.productTaxSelectTarget.value = '';
                        $(`#${this.productIndexValue}_tax_id`).val(null).trigger('change');
                    }

                    this.recalculateOrderTotals()
                    $(document).trigger('orderFormController.productSelectUpdated',
                        {
                            content: this,
                            identifier: this.productIndexValue,
                            isLastProduct: this.lastProductValue
                        }
                    )
                }.bind(this),
                error: function () {
                    $(`#${thisPointer.productIndexValue}`).trigger('orderFormProductController.errorOnProductSelect')
                }
            })
        }
    }

    feeSelectUpdated() {
        this.feeAmountTarget.value = "0.00"

        this.recalculateOrderTotals()
    }

    recalculateTax() {
        let percentage = parseFloat($(`#${this.productIndexValue}_tax_id :selected`).data('percentage')) || 0
        if (percentage > 0) {
            const subTotal = parseFloat(this.subTotalTarget.value) || 0;
            this.productPriceWithoutVat = parseFloat((subTotal / (100 + parseFloat(percentage))) * 100)
            this.taxAmount = parseFloat(subTotal - this.productPriceWithoutVat)
            this.taxAmountTarget.value = this.taxAmount.toFixed(2)
            this.taxPercentage = percentage
        } else {
            this.taxAmount = parseFloat("0")
            this.taxAmountTarget.value = "0.00"
            this.productPriceWithoutVat = parseFloat(this.productTotal)
            this.taxPercentage = 0
        }
    }

    recalculateDiscount() {
        if (this.productDiscountSelectTarget.disabled || $(`#${this.productIndexValue}_discount_by_id`).css('display') === 'none') {
            this.discountAmount = parseFloat(this.discountAmountTarget.value);
            this.initialDiscountAmount = parseFloat(this.discountAmountTarget.value);
        } else {
            this.discountAmount = 0.00;
            this.initialDiscountAmount = 0.00;

            let select = this.productDiscountSelectTarget;
            let percentage = 0;
            let amount = 0;

            if (select.selectedIndex !== -1) {
                let option = select.options[select.selectedIndex];
                percentage = parseFloat(option.dataset.percentage)
                amount = parseFloat(option.dataset.amount)
            }

            if (select.value !== '' && percentage > 0) {
                if(this.productDiscountForIntitialOnly === '1'){
                    this.discountAmount = 0.00
                    this.initialDiscountAmount = parseFloat(this.productTotal * (percentage / 100))
                } else {
                    this.discountAmount = parseFloat(this.productTotal * (percentage / 100))
                    this.initialDiscountAmount = parseFloat(this.productTotal * (percentage / 100))
                }
            } else if (select.value !== '' && amount > 0) {
                if(this.productDiscountForIntitialOnly === '1'){
                    this.discountAmount = 0.00
                    this.initialDiscountAmount = amount
                } else {
                    this.discountAmount = amount
                    this.initialDiscountAmount = amount
                }
            } else if (select.value === '') {
                this.discountAmount = (parseFloat(this.discountAmountTarget.value) || 0)
                this.initialDiscountAmount = (parseFloat(this.discountAmountTarget.value) || 0)
            }

            this.discountAmountTarget.value = this.initialDiscountAmount.toFixed(2)
        }
    }

    setFeePrice() {
        if (this.selectProductFeeIdTarget.disabled || $(`#${this.productIndexValue}_fee_by_id`).css('display') === 'none') {
            this.feeAmount = parseFloat(this.feeAmountTarget.value);
            this.initialFeeAmount = parseFloat(this.feeAmountTarget.value);
        } else {
            this.feeAmount = 0.00;
            this.initialFeeAmount = 0.00;

            let selectedFee = this.selectProductFeeIdTarget.options[this.selectProductFeeIdTarget.selectedIndex]
            let amount = parseFloat(selectedFee.dataset.amount) || 0.00

            if (this.productFeeForInitialOnly === '1' && amount > 0) {
                this.feeAmount = 0.00
                this.initialFeeAmount = amount
            } else if (this.productFeeForInitialOnly !== '1' && amount > 0) {
                this.feeAmount = amount
                this.initialFeeAmount = amount
            } else {
                this.feeAmount = 0
                this.initialFeeAmount = 0
            }

            this.feeAmountTarget.value = this.initialFeeAmount.toFixed(2)
        }
    }

    recalculateSubTotal() {
        if (this.productType === 'subscription') {
            this.subTotalTarget.value = (this.productTotal + this.initialFeeAmount - this.initialDiscountAmount).toFixed(2);
        } else {
            this.subTotalTarget.value = (this.productTotal + this.feeAmount - this.discountAmount).toFixed(2);
        }
    }

    discountSelectUpdated() {
        this.discountAmountTarget.value = "0.00"

        this.recalculateOrderTotals()
    }

    taxSelectUpdated() {
        this.recalculateOrderTotals()
    }

    recalculateOrderTotals() {
        this.productQty = parseInt(this.productQtyTarget.value) || 0.00
        this.productAmount = parseFloat(this.productAmountTarget.value) || 0.00
        this.productTotal = (this.productQty * this.productAmount) || 0.00

        this.recalculateDiscount()
        this.setFeePrice()
        this.recalculateSubTotal()
        this.recalculateTax()

        $(document).trigger('orderFormController.recalculateOrderTotals')
    }

    showAddon = function (target) {
        $(target).show();
        $(target).attr("disabled", false);
    }

    hideAddon = function (target) {
        $(target).hide();
        $(target).attr("disabled", true);
    }

    showMoreLess(e) {
        if ($(e.currentTarget).hasClass("active")) {
            $(e.currentTarget).find(".icon").addClass("ri-arrow-down-s-line");
            $(e.currentTarget).find(".icon").removeClass("ri-arrow-up-s-line");
            $(e.currentTarget).find(".btn_text").html("Show options");
            this.hideAddon($(e.currentTarget).data("addon"));
            $(e.currentTarget).removeClass("active");
        } else {
            $(e.currentTarget).find(".icon").removeClass("ri-arrow-down-s-line");
            $(e.currentTarget).find(".icon").addClass("ri-arrow-up-s-line");
            $(e.currentTarget).find(".btn_text").html("Hide options");
            this.showAddon($(e.currentTarget).data("addon"));
            $(e.currentTarget).addClass("active");
        }
    }

    removeProduct() {
        $(document).trigger('orderFormController.removeProduct', {identifier: this.productIndexValue})
        $(document).trigger('orderFormController.recalculateOrderTotals')
    }

    initializeProductSelect2(excludeIds, isRefresh=false) {
        let excludeIdsArr = [];
        if (excludeIds !== "") {
            excludeIdsArr = excludeIds.split(",");
        }

        let parentProductType = null;
        if (this.parentProductTypeValue === "") {
            const productType = $(`#${this.productIndexValue}_product_id option:selected`).data('product-type')
            parentProductType = productType === "single_payment" ? 1 : null;

            if (this.productIndexValue === "product_0") {
                parentProductType = null
            }
        } else {
            parentProductType = this.parentProductTypeValue === "single_payment" ? 1 : null;
        }

        const dropdownParent = $(`#${this.productIndexValue}_product_id`).parent(".form-select");
        $(`#${this.productIndexValue}_product_id`).select2({
            placeholder: "-- Select product --",
            dropdownParent,
            width: "100%",
            ajax: {
                url: '/channels/' + document.getElementById('orderForm').dataset.channel + '/products.json',
                dataType: 'json',
                type: "GET",
                headers: {
                    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
                },
                data: function (term) {
                    return {
                        term: term,
                        exclude_product_ids: excludeIdsArr,
                        product_type: parentProductType
                    };
                },
                processResults: function (data) {
                    return {
                        results: $.map(data, function (item) {
                            return {
                                text: item.to_label,
                                id: item.id,
                                product_name: item.name,
                                product_type: item.product_type,
                                short_desc: item.short_desc,
                                price: item.price,
                                converted_price_list: item.converted_price_list,
                                fee_id: item.product_fee_id,
                                tax_id: item.product_tax_id,
                                discount_id: item.product_discount_id,
                                collect_initial_payment: item.form_collect_initial,
                                initial_payment_amount: item.form_initial_payment,
                                scheduled_duration_number: item.form_s_duration_number,
                                scheduled_duration_unit: item.form_s_duration_unit,
                                scheduled_interval: item.form_s_interval,
                                total_installment_amount: item.form_total_i_amount,
                                monthly_amount: item.form_monthly_amount,
                                unscheduled: item.form_unscheduled,
                                lock_price: item.lock_price,
                                lock_payment_config: item.lock_payment_config,
                                collection_interval: item.collection_interval,
                                currency: item.currency,
                            }
                        })
                    };
                }
            }
        })
        .on("select2:select", function (e) {
            this.productChanged = true;
            if (this.productIndexValue === "product_0") {
                this.shouldChangeOrderType = true;
            }

            $(`#${this.productIndexValue}`).trigger('orderFormProductController.productSelected', e.params.data.id)
        }.bind(this));

        if (!isRefresh) {
            const selectedVal = $(`#${this.productIndexValue}_product_id option:selected`).val();
            if (selectedVal !== "") {
                this.productChanged = false;
                this.shouldChangeOrderType = false;
                $(`#${this.productIndexValue}`).trigger('orderFormProductController.productSelected', selectedVal);
            }
        }
    }

    initializeDiscountSelect2() {
        const dropdownParent = $(`#${this.productIndexValue}_discount_id`).parent(".form-select");

        $(`#${this.productIndexValue}_discount_id`).select2({
            dropdownParent,
            width: "100%",
            ajax: {
                url: '/channels/' + document.getElementById('orderForm').dataset.channel + '/product_discounts.json',
                dataType: 'json',
                type: "GET",
                headers: {
                    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
                },
                processResults: function (data) {
                    let results = $.map(data, function (item) {
                        var value = item.amount != 0 ? item.amount : item.percentage + "%"
                        return {
                            'data-percentage': item.percentage,
                            'data-amount': item.amount,
                            text: item.name + " - (" + value + ")",
                            id: item.id
                        }
                    })

                    results.unshift({text: "CUSTOM", id: 0})

                    return {results};
                }
            }
        })
        .on("select2:select", function (e) {
            if (e.params.data.text === "CUSTOM") {
                $(`#${this.productIndexValue}_discount_by_id`).hide();
                $(`#${this.productIndexValue}_discount_by_id`).attr("disabled", true);
                $(`#${this.productIndexValue}_discount_custom`).show();
                $(e.target).val(null).trigger("change")

                this.productDiscountSelectTarget.disabled = true;
                this.discountAmountTarget.disabled = false;
                this.cleanSelectedDiscount()
            }

            this.discountAmountTarget.value = "0.00"
            this.recalculateOrderTotals()
        }.bind(this));

        $(`#${this.productIndexValue}_discount_custom [data-show='discount']`).on("click", function () {
            $(`#${this.productIndexValue}_discount_custom`).hide();
            $(`#${this.productIndexValue}_discount_by_id`).show();
            $(`#${this.productIndexValue}_discount_by_id`).attr("disabled", false);

            this.productDiscountSelectTarget.disabled = false;
            this.discountAmountTarget.value = "0.00"
            this.recalculateOrderTotals()
        }.bind(this));
    }

    initializeFeeSelect2() {
        const dropdownParent = $(`#${this.productIndexValue}_fee_id`).parent(".form-select");

        $(`#${this.productIndexValue}_fee_id`).select2({
            dropdownParent,
            width: "100%",
            ajax: {
                url: '/channels/' + document.getElementById('orderForm').dataset.channel + '/product_fees.json',
                dataType: 'json',
                type: "GET",
                headers: {
                    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
                },
                processResults: function (data) {
                    let results = $.map(data, function (item) {
                        return {
                            'data-percentage': item.percentage,
                            'data-amount': item.amount,
                            text: item.name + " - (" + item.amount + ")",
                            id: item.id
                        }
                    })

                    results.unshift({text: "CUSTOM", id: 0})

                    return {results};
                }
            }
        })
        .on("select2:select", function (e) {
            if (e.params.data.text === "CUSTOM") {
                $(`#${this.productIndexValue}_fee_by_id`).hide();
                $(`#${this.productIndexValue}_fee_by_id`).attr("disabled", true);
                $(`#${this.productIndexValue}_fee_custom`).show();
                $(e.target).val(null).trigger("change")

                this.selectProductFeeIdTarget.disabled = true;
                this.feeAmountTarget.disabled = false;
                this.feeAmountTarget.value = "0.00"
                this.selectProductFeeIdTarget.selectedIndex = 0
                this.recalculateOrderTotals()
            }

            this.feeAmountTarget.value = "0.00"
            this.recalculateOrderTotals()
        }.bind(this));

        $(`#${this.productIndexValue}_fee_custom [data-show='fee']`).on("click", function () {
            $(`#${this.productIndexValue}_fee_custom`).hide();
            $(`#${this.productIndexValue}_fee_by_id`).show();
            $(`#${this.productIndexValue}_fee_by_id`).attr("disabled", false);

            this.selectProductFeeIdTarget.disabled = false;
            this.feeAmountTarget.value = "0.00"
            this.recalculateOrderTotals()
        }.bind(this));
    }

    initializeTaxSelect2() {
        const dropdownParent = $(`#${this.productIndexValue}_tax_id`).parent(".form-select");

        $(`#${this.productIndexValue}_tax_id`).select2({
            dropdownParent,
            width: "100%",
        })
        .on("select2:select", function (e) {
            this.recalculateOrderTotals()
        }.bind(this));
    }

    cleanSelectedDiscount() {
        this.discountAmountTarget.value = "0.00"
        this.productDiscountSelectTarget.selectedIndex = 0
    }

    updateQty() {
        if (this.productQtyTarget.value === "")
            this.productQtyTarget.value = 1;

        this.recalculateOrderTotals();
    }

    updatePriceConfig() {
        if (this.productAmountTarget.value === "" || parseFloat(this.productAmountTarget.value).toFixed(2) === "0.00")
        {
            this.productAmountTarget.value = 0.00;

            $(`#${this.productIndexValue}_discount_id`).val(null).trigger('change');
            $(`#${this.productIndexValue}_fee_id`).val(null).trigger('change');
            $(`#${this.productIndexValue}_tax_id`).val(null).trigger('change');

            this.discountAmountTarget.value = 0.00;
            this.feeAmountTarget.value = 0.00;
            this.taxAmountTarget.value = 0.00;
            this.subTotalTarget.value = 0.00;
            $("#order_initial_payment_amount").val(0.00);
            this.initialFeeAmount = 0.00;
            this.feeAmount = 0.00;
            this.initialDiscountAmount = 0.00;
            this.discountAmount = 0.00;
            this.taxAmount = 0.00;
            this.productTotal = 0.00;
        }

        this.recalculateOrderTotals();
    }
}