

    $(".alert-dismissible").fadeTo(15000, 500).slideUp(500, function () {
        $(".alert-dismissible").alert('close');
    });

    $('#order_date_of_birth').mask('00/00/0000');

    // $('#order_create_registration').change(function() {
    //     // this will contain a reference to the checkbox
    //     if (this.checked) {
    //         $('#monthly_amount_div').show();
    //         $('#scheduled_duration_number_div').show()
    //         $('#scheduled_duration_unit').show()
    //         $('#start_date_div').show()
    //         $('#scheduled_interval').show()
    //         $('#scheduled_interval').val(1);
    //         $('#order_scheduled_duration_number').val(0);
    //     } else {
    //         $('#monthly_amount_div').hide();
    //         $('#scheduled_duration_number_div').hide()
    //         $('#scheduled_duration_unit').hide()
    //         $('#start_date_div').hide()
    //         $('#scheduled_interval').hide()
    //         $('#scheduled_interval').val(null);
    //         $('#order_monthly_amount').val('00,00');
    //         $('#order_scheduled_duration_number').val(null);
    //
    //     }
    // });


