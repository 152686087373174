import consumer from "../channels/consumer"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = []

    connect() {
        $('#csv-form').submit(function(e) {
            $('#modal_aside_right').modal('hide');
        });

        $("#user_csv_file").change(function() {
            $("label.custom-file-label").text(this.files[0].name);
        });
    }
}