// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails

import "@hotwired/turbo-rails"

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"

Rails.start()
ActiveStorage.start()

import "./controllers"
import * as bootstrap from "bootstrap"
import jquery from "jquery"


window.jQuery = jquery
window.$ = jquery
require('./js/app')


// Overrides default rails confirmation with bootstrap 4 confirm dialog
Rails.confirm = function(message, element) {
    let $element = $(element)
    let $dialog = $('#confirmation-modal')
    let $content = $dialog.find('#modal-content')
    let $ok = $dialog.find('#ok-button')
    $content.text(message)
    $ok.click(function(event) {
        event.preventDefault()
        $dialog.modal("hide")
        let old = Rails.confirm
        Rails.confirm = function() { return true }
        $element.get(0).click()
        Rails.confirm = old
    })
    $dialog.modal("show")
    return false;
}

document.addEventListener("turbo:before-cache", function() {
    $('.form-select select').select2('destroy');
    $(".modal").removeClass("in");
    $(".modal-backdrop").remove();
    $('body').removeClass('modal-open');
    $('body').css('padding-right', '');
    $(".modal").hide();
    $(".form-tags select").select2("destroy");

})



document.addEventListener("turbo:load", function() {
    $( "[data-toggle='lightbox']" ).one( "click", function() {
        $(this).ekkoLightbox({
            leftArrow: '<span><i class="ri-arrow-left-line"></i></span>',
            rightArrow: '<span><i class="ri-arrow-right-line"></i></span>'
        });
    });

    function copyToClipboard(element) {
        document.execCommand("copy");
    }

    $(document).on("click", "[data-copy]", function (e) {
        e.preventDefault();
        $($(this).data("copy")).select();
        document.execCommand("copy");

        $(this).tooltip({title: "Copied!"}).tooltip("show");
        return $($(this).data("copy")).blur();
    })


    $('[data-toggle="tooltip"]').tooltip({placement: "auto"});
    $('[data-toggle="toastr"]').click(function (e) {
        const toastrData = $(this).data();
        toastr[toastrData.type.toLowerCase()](toastrData.content, toastrData.type);
        return false
    });





    // SELECT2 LAUNCER
    $(function () {
        $(".form-select select").each(function () {
            if (!$(this).data('select2')) {
                const dropdownParent = $(this).parent(".form-select");

                $(this).select2({
                    width: "100%",
                    dropdownParent,
                });

                $(this).on("select2:open", (e) => {
                    const optionsContainer = dropdownParent.find(".select2-results__options");

                    setTimeout(function () {
                        optionsContainer.length ? new SimpleBar(optionsContainer[0], {autoHide: false}) : null;
                    }, 0);
                });

                if (dropdownParent.hasClass("form-select-floating") && !$(this).is(":disabled")) {
                    const $label = $(dropdownParent.find("label")[0]);

                    $label.on("click", () => $(this).select2("open"));

                    $(this).on("select2:open", (e) => {
                        $label.addClass("opened");
                    });
                    $(this).on("select2:close", (e) => {
                        $label.removeClass("opened");
                    });
                }
            }
        });

        $(".form-tags select").each(function () {
            const dropdownParent = $(this).parent(".form-tags");

            $(this).select2({
                placeholder: "Tags",
                width: "100%",
                tags: true,
                tokenSeparators: [","],
                dropdownParent,
            });
        });
    });

    // DATETIME
    $(function () {
        /*
                Aviable data-attributes for input:
                'ranges' - value as key from const ranges
                'min_date' - value in format as const dateFormat
                'max_date' - value in format as const dateFormat
                'start_date' - only for dateranges, datepickers use value attribute, value in format as const dateFormat
                'end_date' - only for dateranges, datepickers use value attribute, value in format as const dateFormat
                'selectable' - no values if present year and month selectable
                'time' - show time selects
             */

        const datepickers = $(".form-datepicker .form-control");
        const dateranges = $(".form-daterange .form-control");
        const dateFormat = "DD/MM/YYYY";
        const ranges = {
            default: {
                Today: [moment(), moment()],
                Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
                "Last 7 Days": [moment().subtract(6, "days"), moment()],
                "Last 30 Days": [moment().subtract(29, "days"), moment()],
                "This Month": [moment().startOf("month"), moment().endOf("month")],
                "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
            },
            order: {
                Today: [moment(), moment()],
                Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
                "Last 7 Days": [moment().subtract(6, "days"), moment()],
                "Last 30 Days": [moment().subtract(29, "days"), moment()],
            },
            sp: {
                Today: [moment(), moment()],
                "Next 7 Days": [moment(), moment().add(7, "days")],
                "Next Month": [moment(), moment().add(1, "month")],
            },
            executed_at: {
                Today: [moment(), moment()],
                Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
                "Last 7 Days": [moment().subtract(6, "days"), moment()],
                "Last 30 Days": [moment().subtract(29, "days"), moment()],
                "This Month": [moment().startOf("month"), moment().endOf("month")],
                "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
            },
            single_demo: {
                Today: [moment(), moment()],
                "7 Days ago": [moment().subtract(6, "days"), moment().subtract(6, "days")],
                "1st of Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").startOf("month")],
            },
            orders: {
                Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
                "Last Week": [moment().subtract(6, "days"), moment()],
                "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
            },
        };

        const defaultOptions = {
            autoUpdateInput: false,
            buttonClasses: "btn btn-sm",
            applyButtonClasses: "btn-primary",
            cancelClass: "btn-info",
            locale: {format: dateFormat, cancelLabel: "Clear"},
        };

        datepickers.each(function () {
            const el = $(this);
            let opt = $.extend({}, defaultOptions);
            opt.parentEl = "filters-form";
            opt.singleDatePicker = true;

            if (el.data("ranges")) opt.ranges = setRanges(el.data("ranges"));
            if (el.data("min-date")) opt.minDate = el.data("min-date");
            if (el.data("max-date")) opt.maxDate = el.data("max-date");
            if (el.data("start-date")) opt.startDate = el.data("start-date");
            if (el.data("end-date")) opt.endDate = el.data("end-date");
            if (el.data("selectable") != undefined) opt.showDropdowns = true;

            if (el.data("time") == "show") {
                opt.timePicker = true;
                opt.timePicker24Hour = true;
                opt.locale.format = "DD/MM/YYYY HH:mm";
            }

            el.daterangepicker(opt)
                .on("apply.daterangepicker", function (ev, picker) {
                    if (el.data("time") == "show") {
                        el.val(picker.startDate.format("DD/MM/YYYY HH:mm"));
                    } else {
                        el.val(picker.startDate.format(dateFormat));
                    }
                })
                .on("cancel.daterangepicker", function () {
                    el.val("");
                });
        });

        dateranges.each(function () {
            const el = $(this);
            let opt = $.extend({}, defaultOptions);
            opt.parentEl = el.parent(".form-daterange");

            if (el.data("ranges")) opt.ranges = setRanges(el.data("ranges"));
            if (el.data("min-date")) opt.minDate = el.data("min-date");
            if (el.data("max-date")) opt.maxDate = el.data("max-date");
            if (el.data("start-date")) opt.startDate = el.data("start-date");
            if (el.data("end-date")) opt.endDate = el.data("end-date");
            if (el.data("start-date") && el.data("end-date")) el.val(moment(el.data("start-date")).format("DD/MM/YYYY") + " - " + moment(el.data("end-date")).format("DD/MM/YYYY"));
            if (el.data("selectable") != undefined) opt.showDropdowns = true;

            function cb(start, end) {
                el.val(start.format(dateFormat) + " - " + end.format(dateFormat));
            }

            el.daterangepicker(opt, cb)
                .on("apply.daterangepicker", function (ev, picker) {
                    el.val(picker.startDate.format(dateFormat) + " - " + picker.endDate.format(dateFormat));
                })
                .on("cancel.daterangepicker", function () {
                    el.val("");
                });
        });

        $(".form-datepicker, .form-daterange").append('<i class="icon ri-calendar-event-line"></i>');

        $(".form-control ~ .icon").click(function (e) {
            const inp = $(this).parent().find("input");
            return inp.is(":disabled") ? false : inp.focus();
        });

        function setRanges(type) {
            return ranges[type] ? ranges[type] : ranges.default;
        }
    });
})

  $(function () {
    $("#change_card input").on("change", function (e) {
      $("#change_card").submit();
    });

    $("#collection_interval").on("change", function (e) {
      if ($(this).val() == "CUSTOM") {
        $("#default_interval").hide();
        $("#custom_interval").show();
      }
    });

    $("input[name='process_date']").on("change", function (e) {
      $(this).val() == 2 ? $("#initial_payment_date_div").show(250) : $("#initial_payment_date_div").hide(250);
    });

    // FILTERS LAYOUT
    $('[data-toggle="filters"]').click(function () {
      if (!$(this).hasClass("active")) {
        $(this).find(".btn__text").html("Hide filters");
        $(this).addClass("active");
        $(".filters-form").addClass("show");
      } else {
        $(this).find(".btn__text").html("Show filters");
        $(this).removeClass("active");
        $(".filters-form").removeClass("show");
      }
    });
  });
