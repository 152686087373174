import consumer from "../channels/consumer"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["status", 'details', 'actions']

    connect() {
        this.subscription = consumer.subscriptions.create(
            {
                channel: "OrderChannel",
                id: this.data.get("id"),
            },
            {
                connected: this._connected.bind(this),
                disconnected: this._disconnected.bind(this),
                received: this._received.bind(this),
            }
        );
    }

    _connected() {
    }

    _disconnected() {
    }

    _received(data) {
        $.ajax({
            type: "get",
            dataType: 'script',
            url: '/refresh_order_details/' + this.data.get("id")
        })

    }
}