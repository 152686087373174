import consumer from "../channels/consumer"
import { Controller } from "@hotwired/stimulus"
import toastr from "toastr";
import moment from "moment";

export default class extends Controller {
    static targets = []

    initialize() {
        const identifier = `{"channel":"ImportCustomerCsvChannel","user_id":"${this.data.get("user-id")}"}`;
        let subscriptions = consumer.subscriptions.findAll(identifier)
        subscriptions.map((subscription) => {
            subscription.unsubscribe();
        })

        this.subscription = consumer.subscriptions.create(
            {
                channel: "ImportCustomerCsvChannel",
                user_id: this.data.get("user-id"),
            },
            {
                connected: this._connected.bind(this),
                disconnected: this._disconnected.bind(this),
                received: this._importSignalReceived.bind(this),
            }
        );
    }

    _connected() {
    }

    _disconnected() {
    }

    _importSignalReceived(data) {
        toastr.options = {
            "closeButton": true,
            "positionClass": "toast-top-right",
            "timeOut": null,
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }

        if (data.status === "csv_import_error") {
            toastr.error(data.message);
        }

        if (data.succeeded_customers) {
            data.succeeded_customers.forEach((customer, index) => {
                let org_name = customer['org_name'];
                let firstname = customer['firstname'];
                let lastname = customer['lastname'];
                let status_class = '';
                let abbreviation = '';

                if (firstname && firstname[0]) {
                    abbreviation = firstname[0].toUpperCase();
                } else {
                    firstname = '';
                }

                if (lastname && lastname[0]) {
                    abbreviation += lastname[0].toUpperCase();
                } else {
                    lastname = '';
                }

                if (org_name === null) {
                    org_name = '';
                }

                if (customer['status'] === 'Active') {
                    status_class = 'active';
                } else {
                    status_class = 'inactive'
                }

                $("#customers-container").prepend(
                    `
                <tr style="background: rgba(0, 0, 0, 0.075);">
                  <td class="text-center">
                      <div class="status--dot ${status_class}" title="" data-toggle="tooltip" data-original-title="${customer['status']==='Active' ? customer['status'] : 'Inactive'}"></div>
                  </td>
                  <td>${customer['customer_id']}</td>
                  <td>
                    <a class="user-profile" href="/customers/${customer['id']}">
                        <div class="avatar" data-name="${abbreviation}"></div>
                        <div class="user">
                          <div class="user__subtitle">
                            ${org_name}
                          </div>
                          <div class="user__title">
                            ${firstname} ${lastname}
                          </div>
                        </div>
                </a>  </td>
                  <td class="d-none d-md-table-cell">
                    <span class="text-truncate" style="display:block;max-width:160px">${customer['email']}</span></td>
                  <td class="d-none d-sm-table-cell text-right">0</td>
                  <td class="d-none d-sm-table-cell text-right">${moment().format("YYYY-MM-DD")}</td>
                </tr>
                `
                );
            })
        }
    }
}