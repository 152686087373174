// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus"
import OrderFormController from "./order_form_controller"
import OrderFormProductController from "./order_form_product_controller"
import ProductFormController from "./product_form_controller"
import OrderController from "./order_controller.js"
import OrderCardRegistrationController from "./order_card_registration_controller.js"
import RefundPaymentController from "./refund_payment_controller.js"
import RequestCardUpdateController from "./request_card_update_controller.js"
import SdManagerController from "./sd_manager_controller.js"
import NewCustomerController from "./new_customer_controller.js"
import EditCustomerController from "./edit_customer_controller.js"
import CurrencyPairFormController from "./currency_pair_form_controller.js"
import CustomReportsController from "./custom_reports_controller.js"
import ChatController from "./chat_controller.js"
import PauseOrderController from "./pause_order_controller.js"
import CustomerCardRegistrationController from "./customer_card_registration_controller"
import DashboardController from "./dashboard_controller"
import CustomerController from "./customer_controller"
import CsvModalController from "./csv_modal_controller"
import MergePaymentsController from "./merge_payments_controller"
import ToastController from "./toast_controller"

const application = Application.start()

// Configure Stimulus development experience
application.warnings = true
application.debug = false
window.Stimulus = application

Stimulus.register("order-form", OrderFormController)
Stimulus.register("order-form-product", OrderFormProductController)
Stimulus.register("product-form", ProductFormController)
Stimulus.register("order", OrderController)
Stimulus.register("order-card-registration", OrderCardRegistrationController)
Stimulus.register("refund-payment", RefundPaymentController)
Stimulus.register("request-card-update", RequestCardUpdateController)
Stimulus.register("sd-manager", SdManagerController)
Stimulus.register("new-customer", NewCustomerController)
Stimulus.register("edit-customer", EditCustomerController)
Stimulus.register("currency-pair-form", CurrencyPairFormController)
Stimulus.register("custom-reports", CustomReportsController)
Stimulus.register("chat", ChatController)
Stimulus.register("pause-order", PauseOrderController)
Stimulus.register("customer-card-registration", CustomerCardRegistrationController)
Stimulus.register("dashboard", DashboardController)
Stimulus.register("customer", CustomerController)
Stimulus.register("csv-modal", CsvModalController)
Stimulus.register("merge-payments", MergePaymentsController)
Stimulus.register("toast", ToastController)

export { application }



