import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static values = {
        msgType: String,
        message: String
    }

    connect() {
        toastr[this.toastMsgTyep()](this.messageValue)
    }

    toastMsgTyep(){
        switch (this.msgTypeValue) {
            case 'notice':
                return 'info'
            case 'success':
                return 'success'
            case 'error':
                return 'error'
            case 'alert':
                return 'warning'
            default:
                return 'info'
        }
    }
}
